import Box from "@mui/material/Box";
import { DataGrid, ptBR } from "@mui/x-data-grid";
import {
  getAdvanceRequests,
  updateDatePayment,
} from "~/services/antecipebackoffice/advanceRequest";
import { useEffect } from "react";
import { useState, useRef } from "react";
import Button from "@mui/material/Button";
import DutysModal from "~/components/modals/DutysModalAntecipe";
import {
  date_AAAA_MM_DD_To_DD_MM_AAAA,
  date_DD_MM_AAAA_To_AAAA_MM_DD,
} from "~/utils/date";
import { getDutysByIdDoctor } from "~/services/antecipebackoffice/dutys";
import CustomPagination from "~/components/CustomPagination/index";
import SnackBar from "~/components/modals/SnackBar/index";
import { numberToBRL } from "~/utils/number";
import ChangeStatusModal from "~/components/modals/ChangeStatusModal";
import { statusTranslate } from "~/utils/advanceRequest";
import { cancel } from "~/services/antecipebackoffice/advanceRequest";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveButton from "~/components/buttons/SaveButton/index";
import FilterBarAdvanceRequest from "../../FilterBarAdvanceRequest";
import ConfirmModal from "../../modals/ConfirmModal";
import GenerateContractModal from "../../modals/GenerateContractModal";

// const rows = [
//   { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
//   { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
//   { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
//   { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
//   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
//   { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
//   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
//   { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
//   { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
// ];

const LIMIT = 10;
const LIMIT_DUTYS = 10;

export default function TableAntecipe() {
  const [rows, setRows] = useState([]);
  const [openDutysModal, setOpenDutysModal] = useState(false);
  const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);
  const [openContractModal, setOpenContractModal] = useState(false);
  const [dutysByDoctor, setDutysByDoctor] = useState([]);
  const [doctorIdSelected, setDoctorIdSelected] = useState(null);
  const [advancePaymentSelected, setAdvancePaymentSelected] = useState(null);
  const [totalDutysRegisters, setTotalDutysRegisters] = useState(0);
  const [totalRegisters, setTotalRegisters] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [cancelRequestId, setCancelRequestId] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState("success");
  const [advancePayment, setAdvancePayment] = useState({});
  const [filterBarHeight, setFilterBarHeight] = useState(null);
  const [filters, setFilters] = useState({});
  const [sortModel, setSortModel] = useState([
    { field: "request_date", sort: "desc" },
  ]);

  const refFilterBar = useRef(null);

  const handleSortModelChange = (newModel) => {
    console.log(newModel);
    if (newModel.length === 0) {
      const orderByRequestDate = sortModel.filter(
        (item) => item.field === "request_date"
      )[0];
      console.log(orderByRequestDate);
      if (orderByRequestDate.sort === "desc") {
        const sortModelTemp = [{ field: "request_date", sort: "asc" }];
        console.log(sortModelTemp);
        setSortModel(sortModelTemp);
        applyOrder(sortModelTemp);
      } else {
        const sortModelTemp = [{ field: "request_date", sort: "desc" }];
        console.log(sortModelTemp);
        setSortModel(sortModelTemp);
        applyOrder(sortModelTemp);
      }
    } else {
      setSortModel(newModel);
      applyOrder(newModel);
    }
  };

  const ButtonChangeState = ({ params }) => {
    const onClick = async () => {
      console.log(params.row);
      setAdvancePayment(params.row);
      setOpenChangeStatusModal(true);
    };

    const isInvalidPaymentDate = !params.row.payment_date;
    const isValidStatus =
      params.row.status !== "done" && params.row.status !== "canceled";
    const isNotPossibleGenerateContract =
      isInvalidPaymentDate || !isValidStatus;

    return (
      <Button disabled={isNotPossibleGenerateContract} onClick={onClick}>
        Trocar Status
      </Button>
    );
  };

  const ButtonShowDutys = ({ params }) => {
    const onClick = async () => {
      setDoctorIdSelected(params.row.id);
      await updateListDutys(params.row.id, { limit: LIMIT_DUTYS, offset: 0 });
    };

    return <Button onClick={onClick}>Ver Plantões</Button>;
  };

  const ButtonGenerateContract = ({ params }) => {
    const onClick = async () => {
      setAdvancePaymentSelected(params.row);
      setOpenContractModal(true);
    };

    const isInvalidPaymentDate = !params.row.payment_date;
    const isValidStatus =
      params.row.status !== "done" && params.row.status !== "canceled";
    const isNotPossibleGenerateContract =
      isInvalidPaymentDate || !isValidStatus;

    return (
      <LoadingButton disabled={isNotPossibleGenerateContract} onClick={onClick}>
        Gerar contrato
      </LoadingButton>
    );
  };

  const handleSubmitCancelRequest = async () => {
    try {
      setLoadingCancel(true);
      await cancel(cancelRequestId);
      showAlertSuccess("Solicitação cancelada com sucesso!");
      await getData();
    } catch (e) {
      showAlertError("Erro ao cancelar solicitação");
      console.error(e);
    } finally {
      setLoadingCancel(false);
      setOpenCancelModal(false);
    }
  };

  const ButtonCancel = ({ params }) => {
    const onClick = async () => {
      setCancelRequestId(params.row.id);
      setOpenCancelModal(true);
    };

    const isValidStatus =
      params.row.status !== "done" &&
      params.row.status !== "canceled" &&
      params.row.status !== "waiting_payment_doctor";

    return (
      <LoadingButton
        color="error"
        disabled={!isValidStatus}
        loading={loadingCancel}
        onClick={onClick}
      >
        Cancelar
      </LoadingButton>
    );
  };

  const InputDate = ({ params, defaultValue }) => {
    const [value, setValue] = useState(
      defaultValue ? defaultValue.slice(0, 10) : ""
    );

    const [loading, setLoading] = useState(false);

    const updateDate = async (id, date) => {
      try {
        setLoading(true);
        await updateDatePayment(id, date);
        showAlertSuccess("Data de pagamento atualizada com sucesso!");
        setLoading(false);
        await getData();
      } catch (e) {
        showAlertError("Erro ao atualizar data!");
        console.error(e);
        setLoading(false);
      }
    };

    const onChange = async (e) => {
      const newValue = e.target.value;
      setValue(newValue);
      const tempRows = rows;
      const index = tempRows.findIndex((duty) => params.id === duty.id);
      tempRows[index][params.field] = newValue;
      setRows(tempRows);
    };

    const onKeyUp = async (e) => {
      if (e.key === "Enter") {
        if (value) {
          await updateDate(params.row.id, value);
        }
      }
    };

    const onClick = async () => {
      console.log(params.row.id, value);
      if (value) {
        await updateDate(params.row.id, value);
      }
    };

    return (
      <>
        <input
          maxLength={10}
          style={{ width: `100%` }}
          value={value}
          type="date"
          min={date_DD_MM_AAAA_To_AAAA_MM_DD(
            new Date().toLocaleDateString().replaceAll("/", "-")
          )}
          onChange={onChange}
          onKeyUp={onKeyUp}
        />
        <SaveButton loading={loading} onClick={onClick} />
      </>
    );
  };

  const updateListDutys = async (idDoctor, params) => {
    setOpenDutysModal(true);
    const dutysByIdDoctor = await getDutysByIdDoctor(
      idDoctor ? idDoctor : doctorIdSelected,
      params
    );
    setTotalDutysRegisters(dutysByIdDoctor.total_items);
    setDutysByDoctor(dutysByIdDoctor.data);
  };

  const createFilterObject = (filters) => {
    const filterObject = {};

    // Verificar se doctorName possui valor
    if (filters.doctorName) {
      filterObject.fullName = filters.doctorName;
    }

    // Verificar se crm possui valor
    if (filters.crm) {
      filterObject.crm = filters.crm;
    }

    // Verificar se crmUF possui valor
    if (filters.crmUf) {
      filterObject.crmUf = filters.crmUf;
    }

    // Verificar se status possui valor
    if (filters.selectedStatus && filters.selectedStatus.length > 0) {
      filterObject.status = filters.selectedStatus;
    }

    // Verificar se startRequestDate possui valor
    if (filters.startRequestDate) {
      filterObject.startRequestDate = filters.startRequestDate;
    }

    // Verificar se endRequestDate possui valor
    if (filters.endRequestDate) {
      filterObject.endRequestDate = filters.endRequestDate;
    }

    return filterObject;
  };

  const applyOrder = async (sortModelPaylod) => {
    const orderByRequestDate = sortModelPaylod.filter(
      (item) => item.field === "request_date"
    )[0];
    if (orderByRequestDate) {
      await getData(1, {
        ...filters,
        orderByRequestDate: orderByRequestDate.sort,
      });
    }
  };

  const applyFilters = async (params) => {
    setPage(1);
    const formatedFilters = createFilterObject(params);
    setFilters(formatedFilters);
    const orderByRequestDate = sortModel.filter(
      (item) => item.field === "request_date"
    )[0];
    await getData(1, {
      ...formatedFilters,
      orderByRequestDate: orderByRequestDate.sort,
    });
  };

  const getData = async (pageSelected, filters) => {
    setIsLoading(true);
    pageSelected = pageSelected ? pageSelected : page;

    const offset = (pageSelected - 1) * LIMIT;

    const data = await getAdvanceRequests(
      {
        limit: LIMIT,
        offset: offset,
      },
      filters
    );

    const totalPages = Math.ceil(data.data.total_items / LIMIT);

    if (pageSelected > totalPages && totalPages !== 0) {
      setPage(totalPages);
      const _data = await getAdvanceRequests(
        {
          limit: LIMIT,
          offset: (totalPages - 1) * LIMIT,
        },
        filters
      );
      setTotalRegisters(_data.data.total_items);
      setRows(_data.data.data);
      setIsLoading(false);
    } else {
      setTotalRegisters(data.data.total_items);
      setRows(data.data.data);
      setIsLoading(false);
    }
  };

  const handleChangePage = async (event, value) => {
    await getData(value);
  };

  const showAlertSuccess = (message) => {
    setMessageAlert(message);
    setTypeAlert("success");
    setOpenAlert(true);
  };

  const showAlertError = (message) => {
    setMessageAlert(message);
    setTypeAlert("error");
    setOpenAlert(true);
  };

  useEffect(() => {
    getData();
    setFilterBarHeight(refFilterBar.current.clientHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "doctor_name",
      headerName: "Nome",
      width: 180,
      editable: false,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "doctor_crm",
      headerName: "CRM",
      width: 70,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "doctor_crm_uf",
      headerName: "CRMUF",
      width: 90,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "company_name",
      headerName: "Empresa",
      width: 180,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "request_date",
      headerName: "Data da solicitação",
      width: 200,
      valueFormatter: ({ value }) =>
        date_AAAA_MM_DD_To_DD_MM_AAAA(value.slice(0, 10)),
      editable: false,
      sortable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "payment_date",
      headerName: "Data de Pagamento",
      width: 165,
      renderCell: (params) => (
        <InputDate params={params} defaultValue={params.value} />
      ),
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "vacancies_qty",
      headerName: "Qtde. Plantões",
      width: 140,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "total_gross_value",
      headerName: "Valor Bruto",
      width: 140,
      editable: false,
      sortable: false,
      valueFormatter: ({ value }) => numberToBRL(value),
      align: "center",
      headerAlign: "center",
    },
    {
      field: "total_pay_value",
      headerName: "Valor a ser pago",
      width: 140,
      editable: false,
      sortable: false,
      valueFormatter: ({ value }) => numberToBRL(value),
      align: "center",
      headerAlign: "center",
    },
    {
      field: "total_fee_value",
      headerName: "Valor total da taxa",
      width: 140,
      editable: false,
      sortable: false,
      valueFormatter: ({ value }) => numberToBRL(value),
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: 340,
      editable: false,
      sortable: false,
      align: "center",
      valueFormatter: ({ value }) => statusTranslate(value),
      headerAlign: "center",
    },
    {
      field: "change-status",
      headerName: "Mudar Status",
      width: 140,
      renderCell: (params) => <ButtonChangeState params={params} />,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "show-dutys",
      headerName: "Ver Plantões",
      width: 200,
      renderCell: (params) => <ButtonShowDutys params={params} />,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "generate-contract",
      headerName: "Gerar Contrato",
      width: 200,
      renderCell: (params) => <ButtonGenerateContract params={params} />,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "cancel",
      headerName: "Cancelar",
      width: 200,
      renderCell: (params) => <ButtonCancel params={params} />,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
  ];

  return (
    <>
      {openContractModal ? (
        <GenerateContractModal
          open={openContractModal}
          setOpen={setOpenContractModal}
          advancePaymentSelected={advancePaymentSelected}
        />
      ) : (
        <></>
      )}
      <DutysModal
        open={openDutysModal}
        setOpen={setOpenDutysModal}
        dutys={dutysByDoctor}
        setDutys={setDutysByDoctor}
        updateListDutys={updateListDutys}
        totalRegisters={totalDutysRegisters}
        updateRequestList={getData}
      />
      <ConfirmModal
        loading={loadingCancel}
        open={openCancelModal}
        handleClose={() => setOpenCancelModal(false)}
        handleConfirm={handleSubmitCancelRequest}
      />
      {openChangeStatusModal ? (
        <ChangeStatusModal
          open={openChangeStatusModal}
          setOpen={setOpenChangeStatusModal}
          advancePayment={advancePayment}
          updateData={getData}
        />
      ) : (
        <></>
      )}
      <SnackBar
        open={openAlert}
        setOpen={setOpenAlert}
        duration={3000}
        type={typeAlert}
        message={messageAlert}
      />
      <Box sx={{ height: "calc(100vh - 64px)", width: "100%" }}>
        <Box
          sx={{
            paddingTop: "12px",
            paddingBottom: "12px",
            paddingLeft: "12px",
          }}
          ref={refFilterBar}
        >
          <FilterBarAdvanceRequest onFilterChange={applyFilters} />
        </Box>
        <Box sx={{ height: `calc(100vh - ${filterBarHeight}px - 66px)` }}>
          <DataGrid
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={rows}
            columns={columns}
            disableColumnFilter
            loading={isLoading}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            components={{
              Pagination: () => (
                <CustomPagination
                  count={Math.ceil(totalRegisters / LIMIT)}
                  page={page}
                  setPage={setPage}
                  onChange={handleChangePage}
                />
              ),
            }}
            disableSelectionOnClick
          />
        </Box>
      </Box>
    </>
  );
}
