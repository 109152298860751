export const login = async (username, password) => {

  const users = {
    "empresa@boasnovasgestao.com.br": {
      userName: "Empresa Médica",
      password: "bQ07*0#!",
      home: "/admin/empresa/solicitacoes",
      type: "medicalcompany"
    },
    "antecipe@boasnovasgestao.com.br": {
      userName: "Antecipe",
      password: "bQ07*0#!",
      home: "/admin/antecipe/solicitacoes",
      type: "antecipebackoffice"
    },
    "admin@boasnovasgestao.com.br": {
      userName: "Antecipe",
      password: "bQ07*0#!",
      home: "/admin/antecipe/solicitacoes",
      type: "admin"
    }
  }
 
  const user = users[username];

  if(user){
    if(user.password === password){
      localStorage.setItem('user', JSON.stringify(user))
      return user;
    }else{
      throw new Error("Login inválido!")
    }
  }else{
    throw new Error("Login inválido!")
  }
}