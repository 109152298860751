import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { login } from "../../services/login/login";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState(false);

  const changeUserName = (e) => {
    setUsername(e.target.value);
    console.log(e);
  };

  const changePassword = (e) => {
    setPassword(e.target.value);
    console.log(e);
  };

  const handleLogin = async () => {
    try {
      const user = await login(username, password);
      if (user) {
        window.location.href = user.home
      }
    } catch (e) {
      console.error(e);
      setIsError(true);
    }
  };

  const onClick = async () => {
    await handleLogin();
  };

  return (
    <Box
      height={"100vh"}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#e7ebf0",
      }}
    >
      <Card sx={{ maxWidth: "40%" }}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            align={"center"}
            marginBottom={"20px"}
            marginTop={"20px"}
            variant="h3"
          >
            Login
          </Typography>
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            type="email"
            value={username}
            onChange={changeUserName}
          />
          <TextField
            sx={{ marginTop: "20px" }}
            id="outlined-basic"
            label="Senha"
            type="password"
            variant="outlined"
            value={password}
            onChange={changePassword}
          />
          {isError ? (
            <Typography sx={{ mt: "12px" }} variant="body" color="error">
              Login inválido!
            </Typography>
          ) : (
            <></>
          )}
          <Button
            onClick={onClick}
            sx={{ marginTop: "20px" }}
            variant="contained"
          >
            {" "}
            Entrar{" "}
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Login;
