import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import LoadingButton from "@mui/lab/LoadingButton";

const ConfirmModal = ({
  open,
  setOpen,
  handleConfirm,
  handleClose,
  loading,
}) => {
  const onClose = (e) => {
    if (typeof handleClose === "function") {
      handleClose(e);
    }
    if (typeof setOpen === "function") {
      setOpen(false);
    }
  };

  const onConfirm = (e) => {
    if (typeof handleConfirm === "function") {
      handleConfirm(e);
    }
    if (typeof setOpen === "function") {
      setOpen(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs"    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Você tem certeza que deseja cancelar?
      </DialogTitle>
      <DialogActions
        sx={{
          width: '100%',
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <LoadingButton
          loading={loading}
          variant="outlined"
          sx={{ borderColor: "gray", color: "gray" }}
          onClick={onClose}
        >
          Voltar
        </LoadingButton>
        <LoadingButton
          loading={loading}
          variant="outlined"
          color="error"
          onClick={onConfirm}
          startIcon={<CancelIcon />}
        >
          Cancelar a solicitação!
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
