import { ClassNames } from "@emotion/react";
import { Typography, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import HeaderDesktopStep1 from "../desktop/HeaderDesktopStep1";
import HeaderMobileStep1 from "../mobile/HeaderMobileStep1";
import FooterDesktopStep1 from "../desktop/FooterDesktopStep1";
import FooterMobileStep1 from "../mobile/FooterMobileStep1"
import useMediaQuery from "@mui/material/useMediaQuery";
import BannerModal from "../BannerModal";

const Step1 = ({ setDisabledNext }) => {

  const isMobile = useMediaQuery("(max-width:1024px)");
  const [bannerModalOpen, setBannerModalOpen] = useState(false);

  useEffect(() => {
    setDisabledNext(false);
  
  }, []);

  return (
    <Box
      sx={{
        // border: "solid 10px black",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >

      {
        isMobile ? <HeaderMobileStep1 /> : <HeaderDesktopStep1 />
      }

      <BannerModal open={bannerModalOpen} setOpen={setBannerModalOpen} isMobile={isMobile}/>

      <Box sx={{
        // border: "solid 3px purple",
        fontFamily: 'Poppins, sans-serif',
        color: 'white',
        textAlign: 'center',
        backgroundColor: '#17384c',
        paddingTop: '12px',
        paddingBottom: '12px',
        paddingRight: isMobile ? '14px' : '104px',
        paddingLeft: isMobile ? '14px' : '104px'
      }}>
        <Typography>
          <Box sx={{
            width: '100%',
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 'Bold',
            fontSize: isMobile ? '20px' : '24px'
          }}>
            SOLICITAÇÃO DE ANTECIPAÇÃO DE PAGAMENTOS DE PLANTÕES NA ANTECIPE INVESTIMENTOS E PARTICIPAÇÕES S/A
          </Box>
        </Typography>
      </Box>

      {
        isMobile ? <FooterMobileStep1 /> : <FooterDesktopStep1 />
      }

    </Box>
  );
};

export default Step1;
