import Box from "@mui/material/Box";
//import PrepaymentFormData from "~/store/PrepaymentFormData.js";
import { useEffect, useState } from "react";
import { getMedicalDutys } from "~/services/medicalcompany/dutys";
import PrepaymentFormData from "~/store/PrepaymentFormData.js";
import { DateRange } from "react-date-range";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import { Typography, Grid } from "@mui/material";
import { ptBR } from "date-fns/locale";
import format from "date-fns/format";
import { addDays } from "date-fns";
import compareAsc from "date-fns/compareAsc";
import compareDesc from "date-fns/compareDesc";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import {
  date_DD_MM_AAAA_To_MM_DD_AAAA,
  date_DD_MM_AAAA_To_AAAA_MM_DD,
  date_AAAA_MM_DD_To_DD_MM_AAAA,
} from "~/utils/date";
import useMediaQuery from "@mui/material/useMediaQuery";
import GenericHeader from "../GenericHeader";
import HeaderDesktopStep4 from "../desktop/HeaderDesktopStep4";
import HeaderMobileStep4 from "../mobile/HeaderMobileStep4"
import IconCifrao from "~/assets/icon-cifrao.png";
import BgCapaBottom from "~/assets/bg-capa-bootom.png";

const Step4 = ({ setDisabledNext }) => {

  const isMobile = useMediaQuery("(max-width:1024px)");

  const [dutys, setDutys] = useState([]);
  const [dutysFiltered, setDutysFiltered] = useState([]);
  const [dutysSelected, setDutysSelected] = useState(
    PrepaymentFormData.data.vacancies
  );
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    crm: PrepaymentFormData.data.crm,
    crmuf: PrepaymentFormData.data.crmuf,
  });
  const [rangeDate, setRangeDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDateRange, setOpenDateRange] = useState(false);
  const [isErrorStartDate, setIsErrorStartDate] = useState(false);
  const [isErrorEndDate, setIsErrorEndDate] = useState(false);

  const addInSelectedList = (duty) => {
    const dutys = dutysSelected;
    dutys.push(duty);
    setDutysSelected([...dutys]);
  };

  const removeDutySelected = (duty) => {
    const dutys = dutysSelected.filter((_duty) => duty.id !== _duty.id);
    setDutysSelected([...dutys]);
  };

  const addAllDutys = () => {
    setDutysSelected([...dutys]);
  };

  const removeAllDutysSelected = () => {
    setDutysSelected([]);
  };

  const handleClickOpenDateRange = (event) => {
    setOpenDateRange(true);
    setAnchorEl(event.target);
  };

  const handleClickCloseDateRange = (event) => {
    setOpenDateRange(false);
    setAnchorEl(null);
  };

  const onChangeStartDateInput = (event) => {
    const value = maskDate(event.target.value);
    if (value.length === 10) {
      const startDate = new Date(date_DD_MM_AAAA_To_MM_DD_AAAA(value));

      const isValidDate = validateDate(startDate);

      if (isValidDate) {
        setIsErrorStartDate(false);
        setRangeDate([
          {
            startDate: startDate,
            endDate: rangeDate[0].endDate,
            key: "selection",
          },
        ]);
        const isValidFilters = !isErrorEndDate;
        getDutysWhenChangeDateRange(
          value,
          format(rangeDate[0].endDate, "dd-MM-yyyy"),
          isValidFilters
        );
      } else {
        setIsErrorStartDate(true);
      }
    } else {
      setIsErrorStartDate(true);
    }

    setFilters({
      ...filters,
      startDate: value,
    });
  };

  const onChangeEndDateInput = (event) => {
    const value = maskDate(event.target.value);
    if (value.length === 10) {
      const endDate = new Date(date_DD_MM_AAAA_To_MM_DD_AAAA(value));

      const isValidDate = validateDate(endDate);

      if (isValidDate) {
        setIsErrorEndDate(false);
        setRangeDate([
          {
            startDate: rangeDate[0].startDate,
            endDate: endDate,
            key: "selection",
          },
        ]);
        const isValidFilters = !isErrorStartDate;
        getDutysWhenChangeDateRange(
          format(rangeDate[0].startDate, "dd-MM-yyyy"),
          value,
          isValidFilters
        );
      } else {
        setIsErrorEndDate(true);
      }
    } else {
      setIsErrorEndDate(true);
    }

    setFilters({
      ...filters,
      endDate: value,
    });
  };

  const onChangeDateRange = (item) => {
    const startDate = format(item.selection.startDate, "dd-MM-yyyy");
    const endDate = format(item.selection.endDate, "dd-MM-yyyy");
    setFilters({
      ...filters,
      startDate: startDate,
      endDate: endDate,
    });
    setRangeDate([item.selection]);
    getDutysWhenChangeDateRange(startDate, endDate, true);
  };

  const maskDate = (value) => {
    let v = value.replace(/\D/g, "").slice(0, 10);
    if (v.length >= 5) {
      return `${v.slice(0, 2)}-${v.slice(2, 4)}-${v.slice(4)}`;
    } else if (v.length >= 3) {
      return `${v.slice(0, 2)}-${v.slice(2)}`;
    }
    return v;
  };

  const validateDate = (date) => {
    const minDate = addDays(new Date(), -90);
    const maxDate = new Date();
    const isAsc =
      compareAsc(date, minDate) === 1 || compareAsc(date, minDate) === 0;
    const isDesc =
      compareDesc(date, maxDate) === 1 || compareDesc(date, maxDate) === 0;
    const isValidDate = isAsc && isDesc;
    return isValidDate;
  };

  const getDutysWhenChangeDateRange = async (
    startDate,
    endDate,
    isValidFilters
  ) => {
    if (isValidFilters) {
      await getDutys(startDate, endDate);
    }
  };

  const getTotalValueDutysSelected = () => {
    const totalValue = dutysSelected
      .map((duty) => {
        return duty.value;
      })
      .reduce((partialSum, a) => partialSum + a, 0);

    return totalValue.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  };

  const getDutys = async (startDate, endDate) => {
    let _filters = {
      ...filters,
      startDate: date_DD_MM_AAAA_To_AAAA_MM_DD(startDate),
      endDate: date_DD_MM_AAAA_To_AAAA_MM_DD(endDate),
    };
    const _dutys = await getMedicalDutys(_filters);
    setDutys(_dutys.data.data);
  };

  useEffect(() => {
    // setDisabledNext(true);
    const dutysFiltered = dutys.filter((duty) => {
      return !dutysSelected.find((_duty) => _duty.id === duty.id);
    });
    setDutysFiltered(dutysFiltered);

    PrepaymentFormData.setDutys(dutysSelected);

    if (dutysSelected.length === 0) {
      setDisabledNext(true);
    } else {
      setDisabledNext(false);
    }
  }, [dutys, dutysSelected]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >

      <GenericHeader />

      {
        isMobile ? <HeaderMobileStep4 /> : <HeaderDesktopStep4 />
      }

      <Box sx={{
        padding: isMobile ? '3%' : '6%',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '16px',
        color: 'black',
        textAlign: 'justify',
        backgroundImage: `url(${BgCapaBottom})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}>

        <Typography variant="body" sx={{ fontSize: '18px' }}>
          <strong>Selecione abaixo um periodo para buscar os plantões:</strong>
        </Typography>
        <Box
          sx={{
            mt: "1rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >

          <Grid container spacing={2} sx={{ marginBottom: '40px' }}>
            <Grid item xs={6}>
              <TextField
                label="Data inicial"
                sx={{ width: '100%' }}
                id="start-date-filter-doctor-dutys"
                value={filters.startDate}
                onChange={onChangeStartDateInput}
                onClick={handleClickOpenDateRange}
                error={isErrorStartDate}
                helperText={isErrorStartDate ? "Insira uma data válida!" : ""}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Data Final" sx={{ width: '100%' }}
                value={filters.endDate}
                onChange={onChangeEndDateInput}
                onClick={handleClickOpenDateRange}
                error={isErrorEndDate}
                helperText={isErrorEndDate ? "Insira uma data válida!" : ""}
              />
            </Grid>
          </Grid>
        </Box>
        <Popover
          id={"date-range-popover"}
          open={openDateRange}
          anchorEl={anchorEl}
          onClose={handleClickCloseDateRange}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <DateRange
            editableDateInputs={true}
            maxDate={new Date()}
            minDate={addDays(new Date(), -90)}
            locale={ptBR}
            onChange={onChangeDateRange}
            moveRangeOnFirstSelection={false}
            ranges={rangeDate}
          />
        </Popover>

        <Typography variant="body" sx={{ mt: "0.6rem", fontSize: '18px' }}>
          <strong>Plantões Realizados:</strong>
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            disabled={dutysFiltered.length === 0}
            sx={{ mt: "6px" }}
            onClick={addAllDutys}
          >
            <PlaylistAddIcon />
            <Typography sx={{ mt: "2px" }}>Selecionar todos</Typography>
          </Button>
          <Typography variant="body">
            ({dutysFiltered.length}) Plantões
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            mt: "0.5rem",
            border: "#c4c4c4 1px solid",
            borderRadius: "4px",
            overflowY: "scroll",
            marginBottom: '40px'
          }}
        >
          {dutys.length === 0 ? (
            <Typography
              variant="body2"
              sx={{ textAlign: "center", mt: "6px", mb: "6px" }}
            >
              Nenhum Plantão foi encontrado para esta data.
              <br />
              Verifique se seus dados estão corretos:
              <br />
              CRM: {PrepaymentFormData.data.crm}
              <br />
              CRMUF: {PrepaymentFormData.data.crmuf}
            </Typography>
          ) : (
            dutysFiltered.map((duty, i) => {
              return (
                <>
                  <Box
                    key={"dutys-" + i}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      mt: "6px",
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        alignItems: "center",
                        ml: "6px",
                        mr: "6px",
                      }}
                    >
                      <Box
                        height={"100%"}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Button onClick={() => addInSelectedList(duty)}>
                          <AddCircleIcon />
                        </Button>
                      </Box>
                    </Box>
                    <Box
                      width={"60%"}
                      sx={{
                        overflow: "hidden",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                      }}
                    >
                      <Box>
                        <Typography variant="body">{duty.company}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="body">{duty.hospital}</Typography>
                      </Box>
                      <Box>
                        <Typography noWrap variant>
                          {duty.value.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          })}
                          *
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      width={"50%"}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        mr: "6px",
                      }}
                    >
                      <Box>
                        <Typography variant="body">
                          {date_AAAA_MM_DD_To_DD_MM_AAAA(duty.date)}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="body">
                          {duty.startHour} - {duty.endHour}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Divider sx={{ mt: "6px" }} />
                </>
              );
            })
          )}
        </Box>

        <Typography variant="body" sx={{ mt: "0.6rem", fontSize: '18px' }}>
          <strong>Plantões Selecionados:</strong>
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            color="error"
            disabled={dutysSelected.length === 0}
            sx={{ mt: "6px" }}
            onClick={removeAllDutysSelected}
          >
            <PlaylistRemoveIcon />
            <Typography sx={{ mt: "2px" }}>Remover Todos</Typography>
          </Button>
          <Typography variant="body">
            ({dutysSelected.length}) Plantões
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            mt: "0.5rem",
            border: "#c4c4c4 1px solid",
            borderRadius: "4px",
            overflowY: "scroll",
          }}
        >
          {dutysSelected.length === 0 ? (
            <Typography
              variant="body2"
              sx={{ textAlign: "center", mt: "6px", mb: "6px" }}
            >
              Nenhum Plantão selecionado.
            </Typography>
          ) : (
            dutysSelected.map((duty, i) => {
              return (
                <>
                  <Box
                    key={"plantao-" + i}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      mt: "6px",
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        alignItems: "center",
                        ml: "6px",
                        mr: "6px",
                      }}
                    >
                      <Box
                        height={"100%"}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Button onClick={() => removeDutySelected(duty)}>
                          <RemoveCircleIcon color="error" />
                        </Button>
                      </Box>
                    </Box>
                    <Box
                      width={"60%"}
                      sx={{
                        overflow: "hidden",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                      }}
                    >
                      <Box>
                        <Typography variant="body">{duty.company}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="body">{duty.hospital}</Typography>
                      </Box>
                      <Box>
                        <Typography noWrap variant="body">
                          {duty.value.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          })}
                          *
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      width={"50%"}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        mr: "6px",
                      }}
                    >
                      <Box>
                        <Typography variant="body">
                          {date_AAAA_MM_DD_To_DD_MM_AAAA(duty.date)}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="body">
                          {duty.startHour} - {duty.endHour}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Divider sx={{ mt: "6px" }} />
                </>
              );
            })
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            marginTop: '40px'
          }}
        >
          <Typography variant="body">
            Valor total: {getTotalValueDutysSelected()}
          </Typography>
        </Box>
        <Typography variant="body2" sx={{ mt: "6px", mb: "2rem" }}>
          * É o valor total sem considerar taxas nem descontos provenientes da
          empresa medica.
        </Typography>
        <Box sx={{
          width: '100%',
          textAlign: 'center',
          marginBottom: '33px'
        }}>
          <img
            src={IconCifrao}
            alt="capa antecipe"
            style={{ width: '16%' }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Step4;
