import Box from "@mui/material/Box";
import { Typography, Grid } from "@mui/material";
import CapaHeader from "~/assets/capa-header.png";
import BgCapaTop from "~/assets/background-capa-top.png";
import Logo from "~/assets/logo.png";
import AntecipeMoney from "~/assets/antecipe-money.png";

const HeaderDesktopStep1 = () => {
  return (
    <Box sx={{
        backgroundImage: `url(${BgCapaTop})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100vh'
      }}>
        <Box sx={{
          backgroundImage: `url(${CapaHeader})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
          height:'60%',
          width:'100%'
        }}>
          <Box>
            <img 
              src={Logo} 
              alt="capa antecipe"
              style={{width:'20%'}} 
            />
          </Box>
        </Box>
        <Box>
            <Grid container spacing={2}>
              <Grid item xs={6} sx={{
                }}>
                <Box></Box>
              </Grid>
              <Grid item xs={6} sx={{
                  marginLeft: '-6%',
                  padding: '0',
                  width: '100%',
                }}>
                <Box sx={{
                    width: '66%',
                   }}>
                  <img 
                    src={AntecipeMoney} 
                    alt="capa antecipe"
                    style={{width:'100%', marginTop:'-55%'}}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className='dot' sx={{
            zIndex: '0',
            position:'inherit',
            marginTop:'-7%'
            }}>
          </Box>
          <Box sx={{
            boxShadow: 16,
            fontFamily: 'Poppins, sans-serif',
            fontSize:'16px',
            color: '#17384c',
            textAlign:'center',
            backgroundColor:'white',
            paddingTop:'24px',
            paddingBottom: '18px',
            paddingRight:'133px',
            paddingLeft:'133px',
            marginTop:'-12.5%',
            marginBottom: '0',
            marginLeft:'23px',
            marginRight:'23px',
            whiteSpace: 'nowrap'
            }}>
            <Typography variant="body">
                A <Box component="span" sx={{fontWeight: 'Bold'}}>ANTECIPE</Box> promove independência financeira a você, médico(a),
                <br />
                disponibilizando pagamento antecipado aos plantões que tenha realizado 
                <br />
                com as empresas parceiras da ANTECIPE.
            </Typography>
          </Box>
      </Box>
  );
}

export default HeaderDesktopStep1;