import { IconButton, Dialog, DialogActions, DialogContent, Button } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import LoadingButton from "@mui/lab/LoadingButton";
import BannerImageDesktop from "~/assets/promo-credito-ideal-desktop.webp";
import BannerImageMobile from "~/assets/promo-credito-ideal-mobile.png";
import Close from '@mui/icons-material/Close';


const BannerModal = ({
  open,
  setOpen,
  handleConfirm,
  handleClose,
  loading,
  isMobile
}) => {
  const onClose = (e) => {
    if (typeof handleClose === "function") {
      handleClose(e);
    }
    if (typeof setOpen === "function") {
      setOpen(false);
    }
  };

  const onConfirm = (e) => {
    if (typeof handleConfirm === "function") {
      handleConfirm(e);
    }
    if (typeof setOpen === "function") {
      setOpen(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={false}>
      <IconButton onClick={onClose} sx={{
        position: 'fixed', top: '30px', right: '30px', color: 'white', display: isMobile ? 'none' : 'block'
      }}>
        <Close sx={{ fontSize: '3.5rem' }} />
      </IconButton>
      <DialogContent
        
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img width="100%" height={isMobile ? '100%' : 'auto'} src={isMobile ? BannerImageMobile : BannerImageDesktop} onClick={() => {
            window.open('https://forms.gle/hKQKg8mXQP8wo5xW8')
          }} style={{ cursor: 'pointer' }}/>
      </DialogContent>
      <DialogActions sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '12px'
      }}>
        <Button
          onClick={() => {
            window.open('https://forms.gle/hKQKg8mXQP8wo5xW8')
          }}
          sx={{
            width: '215px',
            fontFamiliy: 'Poppins, sans-serif',
            fontWeight: 'bold',
            backgroundColor: '#17384c',
            color: 'white',
            '&:hover': {
              backgroundColor: '#05bbe5',
            }
          }}
        >Receber meu crédito</Button>
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            width: '215px',
            fontFamiliy: 'Poppins, sans-serif',
            fontWeight: 'bold',
            color: 'darkgray',
            borderColor: 'darkgray',
            marginLeft: '0 !important',
            display: isMobile ? 'block' : 'none',
            '&:hover': {
              backgroundColor: 'lightgray',
              borderColor: 'darkgray',
            }
          }}
        >Ignorar meu crédito</Button>
      </DialogActions>
    </Dialog>
  );
};

export default BannerModal;
