import { useEffect } from "react";
import { useState, useRef } from "react";
import Box from "@mui/material/Box";
import { DataGrid, ptBR } from "@mui/x-data-grid";
import { getDoctors } from "~/services/antecipebackoffice/doctors";
import CustomPagination from "~/components/CustomPagination/index";
import { Button } from "@mui/material";
import EditDoctorBankModal from "~/components/modals/EditDoctorBankModal";
import EditDoctorPJModal from "~/components/modals/EditDoctorPJModal";
import FilterBarDoctors from "../../../../components/FilterBarDoctors";
// import SnackBar from '~/components/modals/SnackBar/index';

const LIMIT = 10;

export default function TableDoctors() {
  const [rows, setRows] = useState([]);
  const [totalRegisters, setTotalRegisters] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openEditPJModal, setOpenEditPJModal] = useState(false);
  const [idDoctor, setIdDoctor] = useState(false);
  const [doctorName, setDoctorName] = useState("");
  const [filterBarHeight, setFilterBarHeight] = useState(null);
  const [filters, setFilters] = useState({});
  const [sortModel, setSortModel] = useState([
    { field: 'id', sort: 'asc' },
  ]);

  const refFilterBar = useRef(null);

  const getData = async (pageSelected, filters) => {
    setIsLoading(true);
    pageSelected = pageSelected ? pageSelected : page;

    const data = await getDoctors({
      limit: LIMIT,
      offset: (pageSelected - 1) * LIMIT,
    }, filters);

    setTotalRegisters(data.data.total_items);
    setRows(data.data.data);
    setIsLoading(false);
  };

  const applyOrder = async (sortModelPaylod) => {
    const orderById = sortModelPaylod.filter(item => item.field === 'id')[0]
    if(orderById){
      await getData(1, { ...filters, orderById: orderById.sort});
    }
  }

  const applyFilters = async (params) => {
    setPage(1);
    setFilters(params)
    const orderById = sortModel.filter(item => item.field === 'id')[0]
    await getData(1, { ...params, orderById: orderById.sort});
  };

  const handleSortModelChange = (newModel) => {
    if(newModel.length === 0){
        const orderById = sortModel.filter(item => item.field === 'id')[0]
        if(orderById.sort === 'desc'){
          const sortModelTemp = [
            { field: 'id', sort: 'asc' },
          ]
          console.log(sortModelTemp)
          setSortModel(sortModelTemp);
          applyOrder(sortModelTemp)
        }else{
          const sortModelTemp = [
            { field: 'id', sort: 'desc' },
          ]
          console.log(sortModelTemp)
          setSortModel(sortModelTemp);
          applyOrder(sortModelTemp)
        }
      }else{
        setSortModel(newModel);
        applyOrder(newModel)
      }
  };

  const handleChangePage = async (event, value) => {
    await getData(value);
  };

  const handleClickEditBank = (params) => {
    setIdDoctor(params.row.id);
    setDoctorName(params.row.name);
    setOpenEditModal(true);
  };

  const handleClickEditPJ = (params) => {
    setIdDoctor(params.row.id);
    setDoctorName(params.row.name);
    setOpenEditPJModal(true);
  };

  // const showAlertSuccess = (message) => {
  //   setMessageAlert(message);
  //   setTypeAlert("success");
  //   setOpenAlert(true);
  // }

  // const showAlertError = (message) => {
  //   setMessageAlert(message);
  //   setTypeAlert("error");
  //   setOpenAlert(true);
  // }

  useEffect(() => {
    getData();
    setFilterBarHeight(refFilterBar.current.clientHeight)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
      align: "center",
      sortable: true,
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "Nome",
      width: 180,
      editable: false,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "email",
      headerName: "Email",
      width: 180,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "crm",
      headerName: "CRM",
      width: 70,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "crm_uf",
      headerName: "CRMUF",
      width: 90,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "cpf",
      headerName: "CPF",
      width: 120,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "edit-bank",
      headerName: "Dados Bancários",
      width: 180,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <Button onClick={() => handleClickEditBank(params)}>
          Dados Bancários
        </Button>
      ),
      align: "center",
      headerAlign: "center",
    },
    {
      field: "edit-company",
      headerName: "Dados Pessoa Jurídica",
      width: 220,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <Button onClick={() => handleClickEditPJ(params)}>
          Dados Pessoa Jurídica
        </Button>
      ),
      align: "center",
      headerAlign: "center",
    },
  ];

  return (
    <>
      {/* <SnackBar
        open={openAlert}
        setOpen={setOpenAlert}
        duration={3000}
        type={typeAlert}
        message={messageAlert}
      /> */}
      {openEditModal ? (
        <EditDoctorBankModal
          open={openEditModal}
          setOpen={setOpenEditModal}
          idDoctor={idDoctor}
          doctorName={doctorName}
        />
      ) : (
        <></>
      )}
      {openEditPJModal ? (
        <EditDoctorPJModal
          open={openEditPJModal}
          setOpen={setOpenEditPJModal}
          idDoctor={idDoctor}
          doctorName={doctorName}
        />
      ) : (
        <></>
      )}
      <Box sx={{ height: "calc(100vh - 64px)", width: "100%" }}>
        <Box
          sx={{
            paddingTop: "12px",
            paddingBottom: "12px",
            paddingLeft: "12px",
          }}
          ref={refFilterBar}
        >
          <FilterBarDoctors onFilterChange={applyFilters} />
        </Box>
        <Box sx={{ height: `calc(100vh - ${filterBarHeight}px - 66px)` }}>
          <DataGrid
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={rows}
            columns={columns}
            disableColumnFilter
            loading={isLoading}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            components={{
              Pagination: () => (
                <CustomPagination
                  count={Math.ceil(totalRegisters / LIMIT)}
                  page={page}
                  setPage={setPage}
                  onChange={handleChangePage}
                />
              ),
            }}
            disableSelectionOnClick
          />
        </Box>
      </Box>
    </>
  );
}
