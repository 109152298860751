import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import PrepaymentFormData from "~/store/PrepaymentFormData.js";
import { useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import GenericHeader from "../GenericHeader";
import BgCapaBottom from "~/assets/bg-capa-bootom.png";

const Step2 = ({ setDisabledNext }) => {

  const isMobile = useMediaQuery("(max-width:1024x)");

  useEffect(() => {
    setDisabledNext(!PrepaymentFormData.data.acceptTerms);
  });

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }}>

      <GenericHeader />

      <Box
        sx={{
          padding: isMobile ? '3%' : '6%',
          paddingRight: '12%',
          marginBottom: '92px',
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          backgroundImage: `url(${BgCapaBottom})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >

        <Box sx={{
          paddingMargin: isMobile ? '6%' : '12%',
          paddingBottom: '25px',
          fontSize: '20px',
          fontWeight: 'bold',
          textAlign: isMobile ? 'justify' : 'left'
        }}>
          <Typography variant="body">
            Condições da proposta da antecipação de valores realizada pela (ANTECIPE PAGAMENTOS E PARTICIPAÇÕES S/A.) para você!
          </Typography>
        </Box>

        <Box sx={{ textAlign: 'justify' }}>
          <Typography variant="body" pt={2}>
            <strong>Cláusula 01 - Critérios para antecipação:&nbsp;</strong>A
            <strong>&nbsp; ANTECIPE PAGAMENTOS</strong>&nbsp; consultará à empresa
            selecionada na Seção 02 em que você realizou plantões os créditos
            disponíveis ao médico para recebimento conforme a seleção realizada nas
            Seções 4, 5 e 6 abaixo neste formulário.
          </Typography>
          <br /><br />
          <Typography variant="body" pt={2}>
            <strong>Cláusula 02 - Custos da antecipação:&nbsp;</strong>A
            <strong>&nbsp; ANTECIPE PAGAMENTOS</strong>&nbsp; aplicará sobre os
            créditos líquidos, conforme item acima, que o médico tiver a receber
            junto à empresa selecionada em que realizou plantões a taxa de serviço
            de 06,49% para antecipações de plantões a receber em até 30 dias, 09,98%
            para antecipações para plantões a receber daqui 31 e 60 dias e 13,47%
            para antecipações de plantões que você tem a receber entre 61 e 90 dias,
            contados a partir da data de recebimento da antecipação até a data
            estabelecida para repasse ao médico pela empresa selecionada em que
            realizou plantões.
          </Typography>
          <br /><br />
          <Typography variant="body" pt={2}>
            <strong>Cláusula 03 - Segurança da antecipação:&nbsp;</strong>A
            <strong>&nbsp; ANTECIPE PAGAMENTOS</strong>&nbsp; assume todos os riscos
            relacionados à compra realizada dos créditos devidos ao médico pela
            empresa selecionada em que realizou plantões mediante a antecipação
            contratada. Nenhum valor adicional será cobrado ao médico caso a
            ANTECIPE PAGAMENTOS não receba os valores compensatórios correspondentes
            à realização da antecipação contratada.
          </Typography>
          <br /><br />
          <Typography variant="body" pt={2}>
            <strong>Cláusula 04 - Período para resposta:&nbsp;</strong>A
            <strong>&nbsp; ANTECIPE PAGAMENTOS</strong>&nbsp; retornará resposta ao
            médico em até 24 horas úteis após o envio desta solicitação.
          </Typography>
          <br /><br />
          <Typography variant="body" pt={2}>
            <strong>Cláusula 05 - Contratação da antecipação:&nbsp;</strong>A
            <strong>&nbsp; ANTECIPE PAGAMENTOS</strong>&nbsp; avaliará a solicitação
            realizada através do preenchimento deste formulário somente mediante a
            concordância do médico das Seções 03 e 04 e, confirmada viabilidade pela
            empresa selecionada em que realizou plantões, emitirá documento ao
            médico para contratação da antecipação por assinatura digital via
            e-mail.
          </Typography>
          <br /><br />
          <Typography variant="body" pt={2}>
            <strong>Cláusula 06 - Prazo para recebimento:&nbsp;</strong>A
            <strong>&nbsp; ANTECIPE PAGAMENTOS</strong>&nbsp; realizará a
            antecipação dos valores em até 24h úteis após a contratação.
          </Typography>
          <br /><br /><br />
          <Typography variant="body" align="left" pt={2}>
            Concordo com os termos contidos nas cláusulas acima para que a
            <strong>&nbsp; ANTECIPE PAGAMENTOS</strong>&nbsp; me realize oferta de
            antecipação de recebimentos de acordo com meus créditos a receber junto
            as empresas médicas.
          </Typography>
          <br />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={PrepaymentFormData.data.acceptTerms}
                  onChange={(e) => {
                    PrepaymentFormData.setAcceptTerms(e.target.checked);
                    setDisabledNext(!PrepaymentFormData.data.acceptTerms);
                  }}
                />
              }
              label="Sim, estou de acordo."
            />
          </FormGroup>
        </Box>

      </Box>
    </Box>
  );
};

export default Step2;
