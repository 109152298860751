import api from "~/services/apis/antecipeApi";
import { formatDateInHoursAndMinutes } from '~/utils/date';

export const getMedicalDutys = async ({ startDate, endDate, crm, crmuf }) => {
  try {
    return api.get(
      `doctor/vacancies` +
        `?startDate=${startDate}&endDate=${endDate}&crm=${crm}&crmUf=${crmuf}`
    );
  } catch (e) {
    console.log("Error", e);
  }
  //https://sistema.meuplantao.app/api/v1/hdasioudhasodhioihintaa/antecipe/doctorvacancies?startDate=2023-01-01&endDate=2023-01-30&crm=233535&crmUf=SP
};

export const getDutysByIdDoctor = async (id, params) => {
  try {
    console.log(params);
    const data = await api.get(`medicalcompany/doctorrequets/${id}/vacancies?limit=${params.limit}&offset=${params.offset}`);
    const vacancies = data.data.data;
    const listVacancies = vacancies.map((vacancie) => {
      return {
        ...vacancie,
        real_end_hour: vacancie.real_end_hour ? formatDateInHoursAndMinutes(new Date(vacancie.real_end_hour)) : null,
        real_start_hour: vacancie.real_start_hour ? formatDateInHoursAndMinutes(new Date(vacancie.real_start_hour)) : null
      }
    })
    return {
      data: listVacancies,
      total_items: data.data.total_items
    };
  } catch(e) {
    console.log('Error', e)
  }
};

export const updateMedicalDuty = async (duty) => {
  //chamar api aqui
  console.log(duty)
  const validateHour = (hour) => {
    console.log(hour)
    const isNull = hour === null;
    if(isNull || hour === "" || hour === undefined){
      return true;
    }else{
      const isCorrectLength = hour.length === 5;
      const isOnlyNumberAndTwoDots = new RegExp(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/).test(hour);    
  
      const isValidated = (isCorrectLength && isOnlyNumberAndTwoDots); 
      return isValidated;
    }
  }

  const validateValues = () => {
    const validateStartHour = validateHour(duty.real_start_hour)
    const validateEndHour = validateHour(duty.real_end_hour)
    console.log(validateStartHour, validateEndHour)
    return validateEndHour && validateStartHour;
  }
  
  const _duty = {
    "real_start_hour": duty.real_start_hour,
    "real_end_hour": duty.real_end_hour,
    "verified_documents": duty.verified_documents,
    "can_advance": duty.can_advance,
    "is_checked": duty.is_checked,
    "payment_rule": duty.payment_rule
  }

  if(typeof duty.normal_payment_date == 'string'){
    _duty['normal_payment_date'] = duty.normal_payment_date.slice(0,10)
  }

  if(validateValues()){
    const data = await api.patch(`medicalcompany/vacancyadvance/${duty.id}`, _duty);
    return data.data.data.vacancy
  }else{
    throw Error('attribute_invalid', _duty);
  }
};

export const syncDutyById = async (id) => {
  await api.put(`medicalcompany/vacancy/${id}/sync`)
}

export const updateMedicalDuties = async (duties) => {
  if(duties.length){
    const _duties = duties.map((duty) => {
      return {
        "id": duty.id,
        "real_start_hour": duty.real_start_hour,
        "real_end_hour": duty.real_end_hour,
        "normal_payment_date": duty.normal_payment_date.slice(0,10),
        "can_advance": duty.can_advance,
        "is_checked": duty.is_checked,
        "with_discount": duty.with_discount,
      }
    })

    const data = await api.put(`medicalcompany/vacancyadvance/batch`, {
      newVacancyValues: _duties
    });
    return data.data.data
  }else{
    throw Error('no_duties', duties);
  }
}