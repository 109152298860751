import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { Box, FormControl, InputLabel } from "@mui/material";
import { getAllBrasilStates } from "../../utils/advanceRequest";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

const states = getAllBrasilStates();

const defaultValues = {
  fullName: "",
  email: "",
  crm: "",
  crmUf: "",
  cpf: "",
};

const FilterBarDoctors = ({ onFilterChange }) => {
  const [filters, setFilters] = useState({
    fullName: "",
    email: "",
    crm: "",
    crmUf: "",
    cpf: "",
  });

  const clearFilters = () => {
    setFilters({ ...defaultValues });
    if (typeof onFilterChange == "function") {
      onFilterChange({ ...defaultValues });
    }
  };

  const handleInputChange = (event) => {
    console.log(event);
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleApplyFilters = () => {
    // Aqui você pode chamar uma função de callback passando os filtros atualizados
    if (typeof onFilterChange == "function") {
      onFilterChange(filters);
    }
  };

  useEffect(() => {
    const listener = event => {
      console.log(event, event.code, filters)
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        if (typeof onFilterChange == "function") {
          onFilterChange(filters);
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [filters]);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      <TextField
        sx={{ m: 1, width: 200 }}
        label="Nome do Médico"
        name="fullName"
        value={filters.fullName}
        onChange={handleInputChange}
      />
      <TextField
        sx={{ m: 1, width: 200 }}
        label="Email"
        name="email"
        value={filters.email}
        onChange={handleInputChange}
      />
      <TextField
        sx={{ m: 1, width: 100 }}
        label="CRM"
        name="crm"
        value={filters.crm}
        onChange={handleInputChange}
      />
      <FormControl sx={{ m: 1, width: 110 }}>
        <InputLabel id="crmuf-select-label">CRMUF</InputLabel>
        <Select
          id="crmuf"
          labelId="crmuf-select-label"
          label="CRMUF"
          name="crmUf"
          value={filters.crmUf}
          renderValue={(s) => s}
          onChange={handleInputChange}
        >
          {states.map((state) => {
            return (
              <MenuItem key={state.uf} value={state.uf}>
                {state.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <TextField
        sx={{ m: 1, width: 200 }}
        label="CPF"
        name="cpf"
        value={filters.cpf}
        onChange={handleInputChange}
      />
      <Button
        variant="contained"
        sx={{ m: 1, width: 56, height: 56, backgroundColor: "gray" }}
        onClick={clearFilters}
      >
        <FilterAltOffIcon />
      </Button>
      <Button
        sx={{ m: 1, width: 165, height: 56 }}
        variant="contained"
        onClick={handleApplyFilters}
      >
        Aplicar Filtros
      </Button>
    </Box>
  );
};

export default FilterBarDoctors;
