import Box from "@mui/material/Box";
import { Typography, Grid } from "@mui/material";
import CapaHeader from "~/assets/capa-header.png";
import HeaderBg from "~/assets/header-bg.png";
import Logo from "~/assets/logo.png";
import useMediaQuery from "@mui/material/useMediaQuery";

const HeaderMobileStep1 = () => {

    const isTablet = useMediaQuery("(max-width:820px)");
    const isCellPhone = useMediaQuery("(max-width:480px)");

    function getScreen() {
        if (isTablet && isCellPhone) {
            return 'cell'
        }
        if (isTablet && !isCellPhone) {
            return 'tablet'
        }
        return ''
    }

    return (
        <Box sx={{
            backgroundImage: `url(${HeaderBg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
        }}>
            <Box>
                <img
                    src={Logo}
                    alt="capa antecipe"
                    style={{
                        width: (getScreen() == 'cell') ? '45%' : '32%'
                    }}
                />
            </Box>

        </Box>
    );
}

export default HeaderMobileStep1;