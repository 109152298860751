import { useState } from "react";
import { useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import SnackBar from '~/components/modals/SnackBar/index';
import {
  showDoctorBank,
  updateBank,
} from "~/services/antecipebackoffice/doctors";

const EditDoctorBankModal = ({ open, setOpen, handleClose, idDoctor, doctorName }) => {
  const [bank_name, setBankName] = useState("");
  const [account_number, setAccount] = useState("");
  const [bank_agency, setAgency] = useState("");
  const [pix, setPix] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState("success");


  const onClose = (e) => {
    if (typeof handleClose === "function") {
      handleClose(e);
    }
    setOpen(false);
  };

  const editBank = async () => {
    try {
      await updateBank(idDoctor, {
        bank_name,
        account_number,
        bank_agency,
        pix,
      });
      showAlertSuccess("Banco Atualizado com Sucesso!")
    } catch (e) {
      showAlertError("Erro ao atualizar banco, por favor preencha todos os campos obrigatórios!")
      console.error(e);
    }
  };

  const getData = async () => {
    const { data } = await showDoctorBank(idDoctor);

    setBankName(data.bank_name);
    setAccount(data.account_number);
    setAgency(data.bank_agency);
    setPix(data.pix);

    console.log(data);
  };

  const showAlertSuccess = (message) => {
    setMessageAlert(message);
    setTypeAlert("success");
    setOpenAlert(true);
  }

  const showAlertError = (message) => {
    setMessageAlert(message);
    setTypeAlert("error");
    setOpenAlert(true);
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
      <SnackBar
        open={openAlert}
        setOpen={setOpenAlert}
        duration={3000}
        type={typeAlert}
        message={messageAlert}
      />
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Dados Bancários
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          // justifyContent: "space-between",
          // alignItems: "center",
        }}
      >
        <Typography sx={{ mb: "12px", mt: '-5px' }}>Médico: {doctorName}</Typography>
        <TextField
          sx={{ mb: "12px", mt: "6px" }}
          required
          label="Banco"
          value={bank_name}
          onChange={(e) => {
            setBankName(e.target.value);
          }}
        />
        <TextField
          sx={{ mb: "12px" }}
          required
          label="Número da conta"
          value={account_number}
          onChange={(e) => {
            setAccount(e.target.value);
          }}
        />
        <TextField
          sx={{ mb: "12px" }}
          required
          label="Agencia"
          value={bank_agency}
          onChange={(e) => {
            setAgency(e.target.value);
          }}
        />
        <TextField
          label="Pix"
          value={pix}
          onChange={(e) => {
            setPix(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button color="error" onClick={onClose}>
          Cancelar
        </Button>
        <Button onClick={editBank}>Atualizar dados bancários</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDoctorBankModal;
