import api from "~/services/apis/antecipeApi";

export const sendRequest = async (request) => {
  try {
    const body = {
      ...request,
      crmUf: request.crmuf,
      vacancies: request.vacancies.map(vacancie => vacancie.id)
    }
    return await api.post("/doctor/request", body);
  } catch (e) {
    console.error("error", e);
  }
};

