import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import PrepaymentFormData from "~/store/PrepaymentFormData";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import useMediaQuery from "@mui/material/useMediaQuery";
import GenericHeader from "../GenericHeader";
import SendIcon from '@mui/icons-material/Send';
import BgCapaBottom from "~/assets/bg-capa-bootom.png";

const Step5 = ({ setActiveStep }) => {

  const isMobile = useMediaQuery("(max-width:1024x)");

  const date_AAAA_MM_DD_To_DD_MM_AAAA = (date) => {
    let dateSplited = date.substr(0, 10).split("-");
    return dateSplited[2] + "-" + dateSplited[1] + "-" + dateSplited[0];
  };

  const getTotalValueDutysSelected = () => {
    const totalValue = PrepaymentFormData.data.vacancies
      .map((duty) => {
        return duty.value;
      })
      .reduce((partialSum, a) => partialSum + a, 0);

    return totalValue.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >

      <GenericHeader />

      <Box sx={{
        padding: isMobile ? '3%' : '6%',
        marginBottom: '92px',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '16px',
        color: '#17384c',
        textAlign: 'justify',
        backgroundImage: `url(${BgCapaBottom})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}>

        <Box sx={{
          textAlign: 'center',
          marginTop: '-20px',
          marginBottom: '15px'
        }}>

          <div style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap'
          }}>
            <Typography><strong>Revise os dados antes de enviar &nbsp;&nbsp;</strong></Typography>
            <SendIcon />
          </div>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography>
            <strong>Dados Pessoais</strong>
          </Typography>
          <Button onClick={() => {
            PrepaymentFormData.setDutys([]);
            setActiveStep(2)
          }}>
            <EditIcon />
          </Button>
        </Box>
        <Box>
          <Typography>
            <strong>Nome: </strong>
            {PrepaymentFormData.data.fullName}
          </Typography>
          <Typography>
            <strong>E-mail: </strong>
            {PrepaymentFormData.data.email}
          </Typography>
          <Typography>
            <strong>CPF: </strong>
            {PrepaymentFormData.data.cpf}
          </Typography>
          <Typography>
            <strong>CRM: </strong>
            {PrepaymentFormData.data.crm}
          </Typography>
          <Typography>
            <strong>CRMUF: </strong>
            {PrepaymentFormData.data.crmuf}
          </Typography>
        </Box>
        <Box
          sx={{
            mt: '12px',
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography>
            <strong>Plantões selecionados:</strong>
          </Typography>
          <Button onClick={() => setActiveStep(3)}>
            <EditIcon />
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            mt: "0.5rem",
            border: "#c4c4c4 1px solid",
            borderRadius: "4px",
            overflowY: "scroll",
          }}
        >
          {PrepaymentFormData.data.vacancies.length === 0 ? (
            <Typography
              variant="body2"
              sx={{ textAlign: "center", mt: "6px", mb: "6px" }}
            >
              Nenhum Plantão selecionado.
            </Typography>
          ) : (
            PrepaymentFormData.data.vacancies.map((duty, i) => {
              return (
                <>
                  <Box
                    key={"plantao-selecionado-" + i}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      mt: "6px",
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        alignItems: "center",
                        ml: "6px",
                        mr: "6px",
                      }}
                    >
                      <Box
                        width={"50px"}
                        height={"100%"}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CheckCircleIcon color="success" />
                      </Box>
                    </Box>
                    <Box
                      width={"60%"}
                      sx={{
                        overflow: "hidden",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start"
                      }}
                    >
                      <Box>
                        <Typography variant="body" fontWeight='normal'>{duty.company}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="body">{duty.hospital}</Typography>
                      </Box>
                      <Box>
                        <Typography noWrap variant="body">
                          {duty.value.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          })}
                          *
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      width={"50%"}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        mr: "6px",
                      }}
                    >
                      <Box>
                        <Typography variant="body">
                          {date_AAAA_MM_DD_To_DD_MM_AAAA(duty.date)}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="body">
                          {duty.startHour} - {duty.endHour}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Divider sx={{ mt: "6px" }} />
                </>
              );
            })
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Typography variant="body" sx={{ marginTop: '28px', fontSize: '18px' }}>
            Valor total: {getTotalValueDutysSelected()}
          </Typography>
        </Box>
        <Typography variant="body2" sx={{ mt: "6px", mb: "2rem", fontSize: '16px' }}>
          * É o valor total sem considerar taxas nem descontos provenientes da
          empresa medica.
        </Typography>
      </Box>
    </Box>
  );
};

export default Step5;
