import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import useMediaQuery from "@mui/material/useMediaQuery";
import Step1 from "~/pages/AntecipeForm/components/steps/Step1";
import Step2 from "~/pages/AntecipeForm/components/steps/Step2";
import Step3 from "~/pages/AntecipeForm/components/steps/Step3";
import Step4 from "~/pages/AntecipeForm/components/steps/Step4";
import Step5 from "~/pages/AntecipeForm/components/steps/Step5";
import Step6 from "~/pages/AntecipeForm/components/steps/Step6";
import { sendRequest } from "~/services/requestForm";
import PrepaymentFormData from "~/store/PrepaymentFormData.js";
import CircularProgress from "@mui/material/CircularProgress";
import { VerticalAlignBottom } from "@mui/icons-material";
import BannerImageDesktop from "~/assets/promo-credito-ideal-desktop.webp";
import BannerImageMobile from "~/assets/promo-credito-ideal-mobile.png";

const Stepper = () => {

  const ref = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:1024px)");
  const [activeStep, setActiveStep] = useState(0);
  const [disableNext, setDisabledNext] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isContentTallerThanTheScreen, setIsContentTallerThanTheScreen] = useState(false);
  // const [goToNextStep, setGoToNextStep] = useState(true);

  const submitForm = async () => {
    return await sendRequest(PrepaymentFormData.data);
  };

  const handleNext = async () => {
    if (activeStep === 4) {
      setIsLoading(true);
      try {
        const response = await submitForm();
        if (response) {
          setTimeout(() => {
            setIsLoading(false)
          }, 500)
        }
      } catch {
        setTimeout(() => {
          setIsLoading(false)
        }, 500)
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setTimeout(() => {
        setIsLoading(false)
      }, 500)
      // setIsLoading(false);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = [
    {
      component: <Step1 setDisabledNext={setDisabledNext} />,
    },
    {
      label:
        "Condições da proposta de antecipação de valores realizada pela ANTECIPE PAGAMENTOS  (ANTECIPE INVESTIMENTOS E PARTICIPAÇÕES S/A.) para você!",
      component: <Step2 setDisabledNext={setDisabledNext} />,
    },
    {
      label: "Dados Pessoais",
      component: <Step3 setDisabledNext={setDisabledNext} />,
    },
    {
      label: "Plantões para antecipação.",
      component: <Step4 setDisabledNext={setDisabledNext} />,
    },
    {
      label: "Revisar dados.",
      component: (
        <Step5
          setDisabledNext={setDisabledNext}
          setActiveStep={setActiveStep}
        />
      ),
    },
    {
      label: "Solicitação Finalizada",
      component: <Step6 setDisabledNext={setDisabledNext} setActiveStep={setActiveStep} />,
    },
  ];

  const maxSteps = steps.length;

  useEffect(() => {
    document.getElementById("content-step").scroll(0, 0);
  }, [activeStep]);

  useEffect(() => {
    const screenHeight = window.screen.height;
    const contentHeight = ref.current.clientHeight
    setIsContentTallerThanTheScreen(
      contentHeight > screenHeight
    );
  });

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100%",
        maxHeight: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Box
        id="content-step"
        sx={{
          width: '100%',
          height: "100%"
        }}
      >
        <Box ref={ref}>
          {steps[activeStep].component}
        </Box>
        {activeStep === maxSteps - 1 ? (
          <></>
        ) : (
          <div>
            <MobileStepper
            sx={isContentTallerThanTheScreen ?
              {
                background: 'transparent',
                marginTop: '-65px',
                fontFamiliy: 'Poppins, sans-serif',
                fontWeight: 'bold',
                color: '#17384c'
              } : {
                width: 'calc(100% - 16px)',
                position: 'fixed',
                bottom: '0',
                background: 'transparent',
                fontFamiliy: 'Poppins, sans-serif',
                fontWeight: 'bold',
                color: '#17384c'
              }
            }
            variant="text"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="large"
                onClick={handleNext}
                disabled={disableNext || isLoading}
                sx={{
                  fontFamiliy: 'Poppins, sans-serif',
                  fontWeight: 'bold',
                  backgroundColor: '#17384c',
                  color: 'white',
                  '&:disabled': {
                    backgroundColor: 'lightgray',
                    color: 'darkgray'
                  },
                  '&:hover': {
                    backgroundColor: '#05bbe5',
                  }
                }}
              >
                {isLoading ? (
                  <CircularProgress size={20} color="grey" sx={{ mr: "10px" }} />
                ) : (
                  <></>
                )}
                {activeStep === 4 ? "Revisar e enviar" : "Continuar"}
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              (activeStep === 0)
                ? <Box sx={{
                  width: '139.86px'
                }}></Box>
                : <Button
                  size="large"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                  sx={{
                    fontFamiliy: 'Poppins, sans-serif',
                    fontWeight: 'bold',
                    backgroundColor: '#17384c',
                    color: 'white',
                    display: (activeStep === 0) ? 'none' : 'inline-flex',
                    '&:hover': {
                      backgroundColor: '#05bbe5',
                    }
                  }}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Voltar
                </Button>
            }
          />
          {/* <img width="100%" height={isMobile ? '100%' : 'auto'} src={isMobile ? BannerImageMobile : BannerImageDesktop} onClick={() => {
            window.open('https://forms.gle/hKQKg8mXQP8wo5xW8')
          }} style={{ cursor: 'pointer' }}/> */}
          </div>
        )}
      </Box>
    </Box>
  );
};

export default Stepper;
