import { useEffect, useState } from "react";
import { InputLabel, MenuItem, Select, Typography, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import PrepaymentFormData from "~/store/PrepaymentFormData.js";
import useMediaQuery from "@mui/material/useMediaQuery";
import GenericHeader from "../GenericHeader";
import BgCapaBottom from "~/assets/bg-capa-bootom.png";
import { getAllBrasilStates } from '~/utils/advanceRequest'

const Step3 = ({ setDisabledNext }) => {

  const isMobile = useMediaQuery("(max-width:1024x)");

  const [email, setEmail] = useState(PrepaymentFormData.data.email);
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [name, setName] = useState(PrepaymentFormData.data.fullName);
  const [isErrorName, setIsErrorName] = useState(false);
  const [cpf, setCPF] = useState(PrepaymentFormData.data.cpf);
  const [isErrorCPF, setIsErrorCPF] = useState(false);
  const [crm, setCRM] = useState(PrepaymentFormData.data.crm);
  const [isErrorCRM, setIsErrorCRM] = useState(false);
  const [crmuf, setCRMUF] = useState(PrepaymentFormData.data.crmuf ? PrepaymentFormData.data.crmuf : "DEFAULT");
  const [isErrorCRMUF, setIsErrorCRMUF] = useState(false);
  const [accetpTermsData, setAccetpTermsData] = useState(
    PrepaymentFormData.data.acceptTermsData
  );

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    PrepaymentFormData.setEmail(e.target.value);
    validateEmailAndSetError(e.target.value);
    validateAllFormData();
  };

  const onChangeName = (e) => {
    setName(e.target.value);
    PrepaymentFormData.setFullName(e.target.value);
    validateNameAndSetError(e.target.value);
    validateAllFormData();
  };

  const onChangeCPF = (e) => {
    const value = e.target.value;
    const regexOnlyNumbers = new RegExp("^[0-9]*$");
    if (regexOnlyNumbers.test(value)) {
      setCPF(e.target.value);
      PrepaymentFormData.setCPF(e.target.value);
    }
    validateCPFAndSetError(e.target.value);
    validateAllFormData();
  };

  const onChangeCRM = (e) => {
    const value = e.target.value;
    const regexOnlyNumbers = new RegExp("^[0-9]*$");
    if (regexOnlyNumbers.test(value)) {
      setCRM(value);
      PrepaymentFormData.setCRM(value);
    }
    validateCRMAndSetError(value);
    validateAllFormData();
  };

  const onChangeCRMUF = (e) => {
    const value = e.target.value.toUpperCase();
    setCRMUF(value);
    PrepaymentFormData.setCRMUF(value);
    validateCRMUFAndSetError(value);
    validateAllFormData();
  };

  const onChangeAcceptTermsData = (e) => {
    setAccetpTermsData(e.target.checked);
    PrepaymentFormData.setAcceptTermsData(e.target.checked);
    validateAllFormData();
  };

  const validateEmail = (email) => {
    const regex = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/);
    const isValid = regex.test(email);
    return isValid;
  };

  const validateName = (name) => {
    const isNameEmpty = name === "";
    const isFullName = String(name).split(" ").length >= 2;
    const isValid = !isNameEmpty && isFullName;
    return isValid;
  };

  const validateCPF = (cpf) => {
    const regex = new RegExp(
      /([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})/
    );
    const isValid = regex.test(cpf);
    return isValid;
  };

  const validateCRM = (crm) => {
    const isValid = crm !== "";
    return isValid;
  };

  const validateCRMUF = (crmuf) => {
    const isValid = crmuf !== "" && crmuf.toUpperCase() !== "DEFAULT";
    return isValid;
  };

  const validateEmailAndSetError = (email) => {
    const isValid = validateEmail(email);
    setIsErrorEmail(!isValid);
    return isValid;
  };

  const validateNameAndSetError = (name) => {
    const isValid = validateName(name);
    setIsErrorName(!isValid);
    return isValid;
  };

  const validateCPFAndSetError = (cpf) => {
    const isValid = validateCPF(cpf);
    setIsErrorCPF(!isValid);
    return isValid;
  };

  const validateCRMAndSetError = (crm) => {
    const isValid = validateCRM(crm);
    setIsErrorCRM(!isValid);
    return isValid;
  };

  const validateCRMUFAndSetError = (crmuf) => {
    const isValid = validateCRMUF(crmuf);
    setIsErrorCRMUF(!isValid);
    return isValid;
  };

  const validateAllFormData = () => {
    const isValidEmail = validateEmail(email);
    const isValidName = validateName(name);
    const isValidCPF = validateCPF(cpf);
    const isValidCRM = validateCRM(crm);
    const isValidCRMUF = validateCRMUF(crmuf);
    console.log(isValidCRMUF);
    const isValidTermsData = accetpTermsData;

    const isValidForm =
      isValidEmail &&
      isValidName &&
      isValidCPF &&
      isValidCRM &&
      isValidCRMUF &&
      isValidTermsData;

    console.log(isValidForm);

    setDisabledNext(!isValidForm);

    return isValidForm;
  };

  const states = getAllBrasilStates()

  useEffect(() => {
    validateAllFormData();
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >

      <GenericHeader />

      <Box sx={{
        padding: isMobile ? '3%' : '6%',
        marginBottom: '92px',
        textAlign: 'justify',
        backgroundImage: `url(${BgCapaBottom})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}>

        <Box sx={{
          paddingMargin: isMobile ? '6%' : '12%',
          paddingBottom: '25px',
          fontSize: '20px',
          fontWeight: 'bold',
          textAlign: 'justify'
        }}>
          <Typography variant="body">
            Condições da proposta da antecipação de valores realizada pela (ANTECIPE PAGAMENTOS E PARTICIPAÇÕES S/A.) para você!
          </Typography>
        </Box>

        <Typography variant="body" align="left">
          <strong>
            Dados Pessoais
          </strong>
          <br /><br />
          <strong>Termo de Permissão e Concordância</strong> para Compartilhamento de Dados Pessoais de Identificação que foram cedidos por mim anteriormente as empresas em que realizei plantões e todas as informações financeiras decorrentes de minhas atividades nas empresas
          <br /><br />
        </Typography>


        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <TextField
            sx={{ mt: 2 }}
            id="email"
            type="email"
            label="Email"
            variant="outlined"
            value={email}
            onChange={onChangeEmail}
            helperText={!isErrorEmail ? "" : "Email inválido."}
            error={isErrorEmail}
          />
          <TextField
            sx={{ mt: 2 }}
            id="name"
            type="text"
            label="Nome Completo"
            variant="outlined"
            value={name}
            onChange={onChangeName}
            helperText={
              !isErrorName ? "" : "Por favor digite o seu nome completo."
            }
            error={isErrorName}
          />

          <TextField
            sx={{ mt: 2, width: '100%' }}
            id="cpf"
            type="text"
            label="N° CPF"
            maxLength={11}
            variant="outlined"
            inputProps={{ maxLength: 11 }}
            value={cpf}
            onChange={onChangeCPF}
            helperText={
              !isErrorCPF ? "" : "Por favor digite seu CPF corretamente."
            }
            error={isErrorCPF}
          />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                sx={{ mt: 2, width: '100%' }}
                id="crm"
                type="text"
                label="CRM"
                variant="outlined"
                value={crm}
                onChange={onChangeCRM}
                helperText={
                  !isErrorCRM ? "" : "Por favor digite seu CRM corretamente."
                }
                error={isErrorCRM}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                sx={{ mt: 2, width: '100%' }}
                defaultValue="DEFAULT"
                id="crmuf"
                value={crmuf}
                // label="Escolha o estado do CRM"
                onChange={onChangeCRMUF}
                error={isErrorCRMUF}
              >
                <MenuItem value="DEFAULT">Escolha o estado do CRM</MenuItem>
                {states.map((state) => {
                  return <MenuItem value={state.uf}>{state.name}</MenuItem>
                })}
              </Select>
            </Grid>
          </Grid>

        </Box>
        <Box sx={{ paddingTop: '25px' }}>
          <Typography variant="body" align="left" pt={0}>
            Este <strong>Termo</strong> tem como propósito compartilhar todos os{" "}
            <strong>DADOS</strong> DE <strong>IDENTIFICAÇÃO PESSOAL</strong> (Nome Completo, RG, CPF,
            Estado Civil, Profissão, Endereço, Dados Bancários e Foto de
            Identificação) que já foram cedidos por mim anteriormente as empresas em
            que realizei plantões, além de todas as INFORMAÇÕES FINANCEIRAS
            decorrentes de minha atuação junto à referida as empresa.
          </Typography>
          <br /><br />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={accetpTermsData}
                  onChange={onChangeAcceptTermsData}
                />
              }
              label="Sim, estou de acordo. Permito o compartilhamento destes dados com a ANTECIPE PAGAMENTOS."
            />
          </FormGroup>
        </Box>
      </Box>
    </Box>
  );
};

export default Step3;
