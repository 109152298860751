import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import TableViewIcon from "@mui/icons-material/TableView";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

// import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import GroupIcon from '@mui/icons-material/Group';

const dictOptions = {
  "medicalcompany": [
    // {
    //   name: "Dashboard",
    //   route: "/admin/empresa/dashboard",
    //   icon: DashboardIcon,
    // },
    {
      name: "Antecipações",
      route: "/admin/empresa/solicitacoes",
      icon: DoneAllIcon,
    },
  ],
  "antecipebackoffice": [
    // {
    //   name: "Dashboard",
    //   route: "/admin/antecipe/dashboard",
    //   icon: DashboardIcon,
    // },
    {
      name: "Antecipações",
      route: "/admin/antecipe/solicitacoes",
      icon: RequestQuoteIcon,
    },
    {
      name: "Dados do Médico",
      route: "/admin/antecipe/cadastrar-medico",
      icon: GroupIcon,
    },
  ],
  "admin": [
    {
      name: "Empresa Médica",
      route: "/admin/empresa/solicitacoes",
      icon: DoneAllIcon,
    },
    {
      name: "Antecipe",
      route: "/admin/antecipe/solicitacoes",
      icon: RequestQuoteIcon,
    },
    {
      name: "Dados do Médico",
      route: "/admin/antecipe/cadastrar-medico",
      icon: GroupIcon,
    },
  ]
}

const user = JSON.parse(localStorage.getItem('user'));

const options = user ? dictOptions[user.type] : []

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer({ Page }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClickOption = (option) => {
    navigate(option.route);
    setOpen(false);
  };

  const handleClickLogout = () => {
    navigate("/login/");
  };

  useEffect(() => {
    window.addEventListener("click", function (e) {
      const drawer = document.getElementById("clickbox");
      if (!drawer.contains(e.target)) {
        // Clicked outside a drawer
        setOpen(false);
      }
    });

  }, []);

  return (
    <Box id="clickbox" sx={{ display: "flex", flexDirection: "column" }}>
      <CssBaseline />
      <AppBar position="relative" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Antecipe
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {options.map((option, index) => (
            <ListItem
              key={option.name}
              disablePadding
              sx={{ display: "block" }}
            >
              <ListItemButton
                onClick={() => handleClickOption(option)}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {<option.icon />}
                </ListItemIcon>
                <ListItemText
                  primary={option.name}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => handleClickLogout()}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
                onClick={() => {
                  window.localStorage.removeItem('user');
                  window.location.href = "/login"
                }}
              >
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={"Sair"} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          pl: { xs: 7, sm: 8, md: 8, lg: 8, xl: 8 },
        }}
      >
        <Page />
      </Box>
    </Box>
  );
}
