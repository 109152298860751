import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { DataGrid, ptBR } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";
import { maskHourAndMinutes } from "~/utils/date";
import { formatDateInHoursAndMinutes } from "~/utils/date";
import { updateMedicalDuty, updateMedicalDuties } from "~/services/medicalcompany/dutys";
import SnackBar from "~/components/modals/SnackBar/index";
import CustomPagination from "~/components/CustomPagination/index";
import SyncIcon from "@mui/icons-material/Sync";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { syncDutyById } from "~/services/medicalcompany/dutys";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  date_AAAA_MM_DD_To_DD_MM_AAAA,
  date_DD_MM_AAAA_To_AAAA_MM_DD,
} from "~/utils/date";
import SaveButton from "../../buttons/SaveButton";

const LIMIT_DUTYS = 10;

const DutysModal = ({
  open,
  setOpen,
  handleClose,
  dutys,
  setDutys,
  updateListDutys,
  totalRegisters,
  updateRequestList,
}) => {
  const [openAlert, setOpenAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState("success");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  // const [lastInputFocus, setLastInputFocus] = useState("");

  const InputDate = ({ params, defaultValue }) => {
    const [value, setValue] = useState(
      defaultValue ? defaultValue.slice(0, 10) : ""
    );
    const [loading, setLoading] = useState(false);

    const updateDate = async (id) => {
      try {
        setLoading(true);
        setIsLoading(true);
        await updateMedicalDuty(params.row);
        showAlertSuccess("Data de pagamento normal atualizada com sucesso!");
        setLoading(false);
        setIsLoading(false);
        await updateListDutysWithParmas();
      } catch (e) {
        showAlertError("Erro ao atualizar data de pagamento normal!");
        console.error(e);
        setLoading(false);
        setIsLoading(false);
      }
    };

    const onChange = async (e) => {
      const newValue = e.target.value;
      setValue(newValue);
      const tempRows = dutys;
      const index = tempRows.findIndex((duty) => params.id === duty.id);
      tempRows[index][params.field] = newValue;
      setDutys(tempRows);
    };

    const onKeyUp = async (e) => {
      if (e.key === "Enter") {
        if (value) {
          await updateDate(params.row.id, value);
        }
      }
    };

    const onClick = async () => {
      if (value) {
        await updateDate(params.row.id, value);
      }
    };

    return (
      <>
        <input
          maxLength={10}
          style={{ width: `100%` }}
          value={value}
          type="date"
          min={date_DD_MM_AAAA_To_AAAA_MM_DD(
            new Date().toLocaleDateString().replaceAll("/", "-")
          )}
          onChange={onChange}
          onKeyUp={onKeyUp}
          disabled={isLoading}
        />
        <SaveButton loading={loading} onClick={onClick} />
      </>
    );
  };

  const CheckBoxDutys = ({ params }) => {
    const [value] = useState(params.value);
    const changeCheckbox = async (e) => {
      let tempDutys = [...dutys];
      const index = tempDutys.findIndex((duty) => params.id === duty.id);
      tempDutys[index][params.field] = e.target.checked;
      try {
        await updateDuty(tempDutys[index]);
      } catch (e) {
        console.error(e);
      }
    };

    return (
      <Checkbox
        defaultChecked={value ? true : false}
        onChange={changeCheckbox}
      />
    );
  };

  const SelectPaymentRules = ({ params }) => {
    console.log(params)
    const [paymentRule, setPaymentRule ] = useState(params.value)

    const handleChange = async (e) => {
      setPaymentRule(e.target.value);
      let tempDutys = [...dutys];
      const index = tempDutys.findIndex((duty) => params.id === duty.id);
      tempDutys[index][params.field] = e.target.value;
      try {
        await updateDuty(tempDutys[index]);
      } catch (e) {
        console.error(e);
      }
    }

    return (
      <select
        id={`payment-rule-${params.row.id}`}
        value={paymentRule}
        label="Regra de pagamento"
        onChange={handleChange}
      >
        <option value={'proportional'}>Proporcional com limite</option>
        <option value={'total'}>Integral</option>
        <option value={'proportional-overtime'}>Proporcional sem limite de tempo</option>
      </select>
    )
  }

  const InputHours = ({ params, handleBlur, defaultValue }) => {
    const [value, setValue] = useState(defaultValue ? defaultValue : "");
    const [loading, setLoading] = useState(false);

    const onChange = (e) => {
      // let tempDutys = [...dutys];
      const newValue = maskHourAndMinutes(e.target.value);
      setValue(newValue);
      const index = dutys.findIndex((duty) => params.id === duty.id);

      const tempDutys = dutys;
      tempDutys[index][params.field] = newValue;
      setDutys(tempDutys);
      e.target.focus();
    };

    const onKeyUp = async (e) => {
      if (e.key === "Enter") {
        try {
          setLoading(true);
          setIsLoading(true)
          await updateDuty(params.row);
          setLoading(false);
          setIsLoading(false)
        } catch (e) {
          console.error(e);
          setIsLoading(false)
          setLoading(false);
        }
      }
    };

    const onClick = async (e) => {
      try {
        setLoading(true);
        setIsLoading(true)
        await updateDuty(params.row);
        setLoading(false);
        setIsLoading(false)
      } catch (e) {
        console.error(e);
        setIsLoading(false)
        setLoading(false);
      }
    };

    return (
      <>
        <input
          key={params.row.id + "_" + params.field}
          id={params.row.id + "__" + params.field}
          maxLength={5}
          style={{ width: `100%` }}
          value={value}
          onChange={onChange}
          onKeyUp={onKeyUp}
          disabled={isLoading}
        />
        <SaveButton loading={loading} onClick={onClick} />
      </>
    );
  };

  const SyncButton = ({ params }) => {
    const onClick = async (e) => {
      try {
        await syncDutyById(params.row.id);
        setMessageAlert("Plantão sincronizado com sucesso!");
        setTypeAlert("success");
        setOpenAlert(true);
        try {
          await updateListDutysWithParmas();
        } catch (e) {
          console.error(e);
        }
      } catch (e) {
        setTypeAlert("error");
        setMessageAlert("Ocorreu algum erro ao sincronizar o plantão!");
        setOpenAlert(true);
      }
    };

    return (
      <IconButton onClick={onClick}>
        <SyncIcon />
      </IconButton>
    );
  };

  const handleBlurInput = async (params) => {
    try {
      await updateDuty(params.row);
    } catch (e) {
      console.error(e);
    }
  };

  const updateListDutysWithParmas = async (pageParam) => {
    pageParam = pageParam ? pageParam : page;

    if (typeof updateListDutys === "function") {
      try {
        setIsLoading(true);
        await updateListDutys(false, {
          limit: LIMIT_DUTYS,
          offset: (pageParam - 1) * LIMIT_DUTYS,
        });
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
  };

  const updateDuty = async (duty) => {
    try {
      await updateMedicalDuty({ ...duty });
      await updateListDutysWithParmas();
      showAlertSuccess("Plantão atualizado com sucesso!");
    } catch (e) {
      console.error(e)
      if (e.message === "attribute_invalid") {
        showAlertError("Valor inserido não é valido!");
      } else {
        showAlertError("Ocorreu algum erro ao atualizar o plantão!");
      }
    }
  };

  const updateAllDuties = async () => {
    try {
      await updateMedicalDuties(dutys);
      await updateListDutysWithParmas();
      showAlertSuccess("Plantões atualizados com sucesso!");
    } catch (e) {
      showAlertError("Ocorreu um erro ao atualizar os plantões, por favor verifique se todos os dados estão corretos.");
    }
  };

  const saveAll = () => {
    
  }

  const handleChangePage = async (event, value) => {
    setPage(value);
    await updateListDutysWithParmas(value);
  };

  const onClose = async (e) => {
    if (typeof handleClose === "function") {
      handleClose(e);
    }
    if (typeof setOpen === "function") {
      setOpen(false);
      setPage(1);
      if (typeof updateRequestList === "function") {
        await updateRequestList();
      }
    }
  };

  const showAlertSuccess = (message) => {
    setMessageAlert(message);
    setTypeAlert("success");
    setOpenAlert(true);
  };

  const showAlertError = (message) => {
    setMessageAlert(message);
    setTypeAlert("error");
    setOpenAlert(true);
  };

  const columns = [
    {
      field: "update",
      headerName: "",
      width: 60,
      renderCell: (params) => <SyncButton params={params} />,
      editable: false,
      sortable: false,
    },
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "hospital_name",
      headerName: "Hospital",
      width: 200,
      editable: false,
      sortable: false,

      align: "center",
      headerAlign: "center",
    },
    {
      field: "date",
      headerName: "Data",
      width: 110,
      valueFormatter: ({ value }) =>
        date_AAAA_MM_DD_To_DD_MM_AAAA(value.slice(0, 10)),
      editable: false,
      sortable: false,
    },
    {
      field: "normal_payment_date",
      headerName: "Data de Pagamento Normal",
      width: 190,
      renderCell: (params) => (
        <InputDate params={params} defaultValue={params.value} />
      ),
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "gross_value",
      headerName: "Valor bruto",
      width: 110,
      valueFormatter: ({ value }) => {
        const valueFormated = Number(value).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
        return valueFormated;
      },
      editable: false,
      sortable: false,
    },
    {
      field: "real_net_value",
      headerName: "Valor Proporcional",
      width: 110,
      valueFormatter: ({ value }) => {
        const valueFormated = Number(value).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
        return valueFormated;
      },
      editable: false,
      sortable: false,
    },
    {
      field: "start_hour",
      headerName: "Hora de inicio",
      width: 110,
      valueFormatter: ({ value }) => {
        return formatDateInHoursAndMinutes(new Date(value));
      },
      editable: false,
      sortable: false,
    },
    {
      field: "end_hour",
      headerName: "Hora da saida",
      width: 110,
      valueFormatter: ({ value }) => {
        return formatDateInHoursAndMinutes(new Date(value));
      },
      editable: false,
      sortable: false,
    },
    {
      field: "real_start_hour",
      headerName: "Hora de inicio realizada",
      width: 150,
      renderCell: (params) => (
        <InputHours
          defaultValue={
            dutys[dutys.findIndex((duty) => duty.id === params.id)][
              params.field
            ]
          }
          params={params}
          handleBlur={handleBlurInput}
        />
      ),
      editable: false,
      sortable: false,
    },
    {
      field: "real_end_hour",
      headerName: "Hora da saida realizada",
      width: 150,
      renderCell: (params) => (
        <InputHours
          defaultValue={
            dutys[dutys.findIndex((duty) => duty.id === params.id)][
              params.field
            ]
          }
          params={params}
          handleBlur={handleBlurInput}
        />
      ),
      editable: false,
      sortable: false,
    },
    {
      field: "payment_rule",
      headerName: "Regra de pagamento",
      width: 250,
      renderCell: (params) => <SelectPaymentRules params={params} />,
      align: "center",
      headerAlign: "center",
      editable: false,
      sortable: false,
    },
    {
      field: "verified_documents",
      headerName: "Documentos Verificados?",
      width: 200,
      renderCell: (params) => <CheckBoxDutys params={params} />,
      editable: false,
      sortable: false,
    },
    {
      field: "can_advance",
      headerName: "Pode Adiantar?",
      width: 110,
      renderCell: (params) => <CheckBoxDutys params={params} />,
      editable: false,
      sortable: false,
    },
    {
      field: "is_checked",
      headerName: "Já Revisado?",
      width: 110,
      renderCell: (params) => <CheckBoxDutys params={params} />,
      editable: false,
      sortable: false,
    },
    // {
    //   field: "obs",
    //   headerName: "Observação",
    //   width: 300,
    //   editable: true,
    // },
    // {
    //   field: "actions",
    //   type: "actions",
    //   width: 120,
    //   renderCell: (params) => />,
    //   editable: false,
    // },
  ];

  return (
    <Dialog open={open} onClose={onClose} fullScreen={true} maxWidth="xl">
      <SnackBar
        open={openAlert}
        setOpen={setOpenAlert}
        duration={3000}
        type={typeAlert}
        message={messageAlert}
      />
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <IconButton onClick={onClose} sx={{ mr: "6px" }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography sx={{ fontWeight: "bold", fontSize: "1.3rem" }}>
          Validar plantões do médico
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DataGrid
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rows={dutys}
          autoHeight={true}
          columns={columns}
          pageSize={10}
          loading={isLoading}
          // autoPageSize
          disableColumnFilter
          components={{
            Pagination: () => (
              <CustomPagination
                count={Math.ceil(totalRegisters / LIMIT_DUTYS)}
                page={page}
                setPage={setPage}
                onChange={handleChangePage}
              />
            ),
          }}
          disableSelectionOnClick
          // rowsPerPageOptions={[10]}
        />
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ ml: "16px" }}>
          <Button
            sx={{ mr: "6px" }}
            variant="outlined"
            color="error"
            onClick={onClose}
          >
            Fechar
          </Button>
        </Box>
        <Box sx={{ ml: "16px" }}>
          <Button
            sx={{ mr: "6px" }}
            variant="outlined"
            color="primary"
            onClick={updateAllDuties}
          >
            Salvar Todos
          </Button>
        </Box>
      </DialogActions>
      {/* onPageChange={onPageChange}
        onSortModelChange={onSortModelChange} */}
    </Dialog>
  );
};

export default DutysModal;
