import api from "~/services/apis/antecipeApi";

export const getDoctors = async (limitParams, filtersParams) => {
  return api.get(
    `antecipebackoffice/doctor?limit=${limitParams.limit}&offset=${limitParams.offset}`,
    { params: filtersParams }
    // + `?startDate=${startDate}&endDate=${endDate}&crm=${crm}&crmUf=${crmuf}`
  );
};

export const showDoctorBank = (id) => {
  return api.get(`antecipebackoffice/doctor/${id}/bank`);
};

export const updateBank = (id, body) => {
  if (id && body && body.bank_name && body.account_number && body.bank_agency) {
    return api.patch(`antecipebackoffice/doctor/${id}/bank`, {
      bank_name: body.bank_name,
      account_number: body.account_number,
      bank_agency: body.bank_agency,
      pix: body.pix ? body.pix : null,
    });
  } else {
    throw new Error(`Body inválido. | Body: ${body}`);
  }
};

export const showDoctorCompany = (id) => {
  return api.get(`antecipebackoffice/doctor/${id}/company`);
};

export const updateDoctorCompany = (id, body) => {
  if (
    id &&
    body &&
    body.company_name &&
    body.cnpj &&
    body.company_address &&
    body.company_cep &&
    body.rg &&
    body.rg_issuer
  ) {
    return api.patch(`antecipebackoffice/doctor/${id}/company`, {
      company_name: body.company_name,
      cnpj: body.cnpj,
      company_address: body.company_address,
      company_cep: body.company_cep ? body.company_cep : null,
      rg: body.rg ? body.rg : null,
      rg_issuer: body.rg_issuer ? body.rg_issuer : null,
    });
  } else {
    throw new Error(`Body inválido. | Body: ${body}`);
  }
};
