import { useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SnackBar from "~/components/modals/SnackBar/index";
import { getUrlForContract } from "~/services/antecipebackoffice/advanceRequest";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import CloseIcon from "@mui/icons-material/Close";

const GenerateContractModal = ({
  open,
  setOpen,
  handleClose,
  advancePaymentSelected,
}) => {
  const [contractType, setContractType] = useState("PF");
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState("success");

  const onClose = (e, reason) => {
    if (loading) 
        return;

    if (typeof handleClose === "function") {
      handleClose(e);
    }
    setOpen(false);
  };

  const generateContract = async () => {
    try {
      setLoading(true);
      const data = await getUrlForContract(
        advancePaymentSelected.id,
        contractType
      );

      window.open(data);

      showAlertSuccess("Contrato gerado com sucesso!");
    } catch (e) {
      console.error(e);
      if (e.name === "without-data") {
        showAlertWarning(e.message);
      } else {
        showAlertError(e.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const showAlertSuccess = (message) => {
    setMessageAlert(message);
    setTypeAlert("success");
    setOpenAlert(true);
  };

  const showAlertError = (message) => {
    setMessageAlert(message);
    setTypeAlert("error");
    setOpenAlert(true);
  };

  const showAlertWarning = (message) => {
    setMessageAlert(message);
    setTypeAlert("warning");
    setOpenAlert(true);
  };

  const handleChangeTypeContract = (e) => {
    setContractType(e.target.value);
  };

  // useEffect(() => {
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
      <SnackBar
        open={openAlert}
        setOpen={setOpenAlert}
        duration={3000}
        type={typeAlert}
        message={messageAlert}
      />
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography variant="title" fontWeight="bold">
            Contratos
          </Typography>
        </Box>
        <Box>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          // justifyContent: "space-between",
          // alignItems: "center",
        }}
      >
        <Typography sx={{ mb: "12px", mt: "-5px" }}>
          <Typography variant="subtitle1">
            Médico: {advancePaymentSelected.doctor_name}
          </Typography>
        </Typography>
        <FormControl>
          <RadioGroup
            row
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <FormControlLabel
              checked={contractType === "PF"}
              value="PF"
              onChange={handleChangeTypeContract}
              control={<Radio />}
              label="Pessoa Física"
            />
            <FormControlLabel
              checked={contractType === "PJ"}
              value="PJ"
              onChange={handleChangeTypeContract}
              control={<Radio />}
              label="Pessoa Jurídica"
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions
        sx={{
          height: '67px',
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingButton sx={{ paddingBottom: '0' }} loading={loading} onClick={generateContract}>
          Gerar Contrato
        </LoadingButton>
        {loading ? (
          <Typography color="gray" variant="caption">
            *Isso pode levar alguns segundos por favor aguarde...
          </Typography>
        ) : (
          <></>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default GenerateContractModal;
