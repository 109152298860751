class PrepaymentFormData {
    constructor(){
        this.data = {
            acceptTerms: false,
            acceptTermsData: false,
            email: '',
            fullName: '',
            cpf: '',
            crm: '',
            crmuf: '',
            vacancies: [] // os dutys
        }
    }
    setDutys(dutys) {
        this.data.vacancies = dutys;
    }
    setAcceptTerms(value){
        this.data.acceptTerms = value;
    }
    setAcceptTermsData(value){
        this.data.acceptTermsData = value;
    }
    setEmail(value){
        this.data.email = value;
    }
    setFullName(value){
        this.data.fullName = value;
    }
    setCPF(value){
        this.data.cpf = value;
    }
    setCRM(value){
        this.data.crm = value;
    }
    setCRMUF(value){
        this.data.crmuf = value;
    }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new PrepaymentFormData();