import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { isLogged } from "./utils/user";

import Login from "~/pages/Login"
import AntecipeForm from "~/pages/AntecipeForm"
import CompanyDashboard from "~/pages/privates/medicalcompany/Dashboard"
import CompanyRequests from "~/pages/privates/medicalcompany/Requests"
import BackOfficeDashboard from "~/pages/privates/antecipebackoffice/Dashboard"
import BackOfficeRequests from "~/pages/privates/antecipebackoffice/Requests"
import BackOfficeRegisterDoctor from "~/pages/privates/antecipebackoffice/RegisterDoctor"


import SideBarAndTopBarLayout from "~/layouts/SideBarAndTopBarLayout"

import "./index.css";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

const PrivateRoute = ({ ElementToRender }) => {

  useEffect(() => {
    const isAuth = isLogged();
    if(!isAuth){
      window.location.href = "/login"
    }
  }, [])
  
  return <SideBarAndTopBarLayout Page={ElementToRender} />
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <AntecipeForm />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/admin/empresa/dashboard",
    element: <PrivateRoute ElementToRender={CompanyDashboard} />,
  },
  {
    path: "/admin/empresa/solicitacoes",
    element: <PrivateRoute ElementToRender={CompanyRequests} />,
  },
  {
    path: "/admin/antecipe/dashboard",
    element: <PrivateRoute ElementToRender={BackOfficeDashboard} />,
  },
  {
    path: "/admin/antecipe/solicitacoes",
    element: <PrivateRoute ElementToRender={BackOfficeRequests} />,
  },
  {
    path: "/admin/antecipe/cadastrar-medico",
    element: <PrivateRoute ElementToRender={BackOfficeRegisterDoctor} />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);