import Box from "@mui/material/Box";
import { Typography, Grid } from "@mui/material";
import BgCapaBottom from "~/assets/bg-capa-bootom.png";
import IconMoney from "~/assets/icon-money.png";
import IconCifrao from "~/assets/icon-cifrao.png";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const FooterMobileStep1 = () => {
    return (

        <Box sx={{
            backgroundImage: `url(${BgCapaBottom})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            paddingBottom: '90px'
        }}>


            <Box sx={{
                padding: '13px',
                display: 'flex',
                alignItems: 'center',
                marginTop: '25px',
            }}>
                <img
                    src={IconMoney}
                    alt="capa antecipe"
                    style={{ width: '60px' }}
                />
                <Typography variant="body" align="left" pt={2} sx={{
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '14px'
                }}>
                    <>
                        As solicitações realizadas serão pagas em até 24h úteis após a o envio desta solicitação.
                    </>
                </Typography>
            </Box>


            <Typography variant="body" align="left" p={5}>
                <Box sx={{
                    padding: '14px',
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: '18px',
                    paddingLeft: '16px',
                    textAlign: 'justify'
                }}>
                    Com custos menores que os juros do cheque especial e da maioria dos empréstimos disponíveis do mercado, a ANTECIPE é uma ferramenta para seu melhor planejamento financeiro.<br />
                </Box>
            </Typography>


            <Box sx={{
                border: 'double 1em transparent',
                borderRadius: '30px',
                backgroundOrigin: 'border-box',
                backgroundClip: 'content-box, border-box',
                backgroundImage: 'linear-gradient(120deg, white, #9cdeff)',
                marginLeft: '15px',
                marginRight: '15px',
                marginBottom: '38px',
                marginTop: '-25px',
                fontFamily: 'Poppins, sans-serif',
                textAlign: 'center',
                width: 'auto',
                color: '#17384c'
            }}>
                <Typography variant="body" align="left" pt={2}>
                    <Box sx={{
                        fontWeight: "bold",
                        textAlign: 'center',
                        fontSize: '19px'
                    }}>
                        O custo total sob o valor da antecipação é de:

                        <img
                            src={IconCifrao}
                            alt="capa antecipe"
                            style={{ width: '60px', marginLeft: '13px', marginTop: '12px', marginBottom: '-20px' }}
                        />

                    </Box>
                </Typography>
                <Box sx={{
                    marginTop: '50px',
                    marginBottom: '40px',
                }}>
                    <Typography variant="body" align="left">
                        <Box component="span" sx={{ fontWeight: 'Bold', paddingLeft: '5px', paddingRight: '5px' }}>06,49%</Box> para antecipações de plantões que você tem a receber nos
                        próximos 30 dias;<br /><br />
                        <Box component="span" sx={{ fontWeight: 'Bold', paddingLeft: '5px', paddingRight: '5px' }}>09,98%</Box> para antecipações de plantões que você tem a receber entre 31
                        e 60 dias;<br /><br />
                        <Box component="span" sx={{ fontWeight: 'Bold', paddingLeft: '5px', paddingRight: '5px' }}>13,47%</Box> para antecipações de plantões que você tem a receber entre 61
                        e 90 dias.<br /><br />
                    </Typography>
                    <Typography variant="body" align="left" pt={2}>
                        <>
                            Nesta fase, trata-se somente do pedido para você avaliar, aceitar e validar a contratação.
                        </>
                    </Typography>
                </Box>
            </Box>

            <Typography variant="body" align="left" pt={2}>
                <Box sx={{
                    marginLeft: '26px',
                    marginBottom: '12px',
                    fontWeight: "bold",
                    fontSize: '18px',
                    color: '#17384c'
                }}>
                    Em caso de dúvidas, fale com nossa equipe de atendimento: via WhatsApp ou Telefone: 19 9995-7432 <WhatsAppIcon sx={{ marginBottom: '-5px' }} />
                </Box>
            </Typography>
        </Box>
    );
}

export default FooterMobileStep1;
