import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { statusTranslate, getListStatus } from "~/utils/advanceRequest";
import { useEffect } from "react";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { updateStatus } from '~/services/antecipebackoffice/advanceRequest';
import SnackBar from '~/components/modals/SnackBar/index';

const ChangeStatusModal = ({ open, setOpen, handleClose, advancePayment, updateData }) => {
  const listOrderedOfStatus = getListStatus();
  const indexOfDefaultStatus = listOrderedOfStatus.findIndex(
    (_status) => _status === advancePayment.status
  )

  const [status, setStatus] = useState();
  const [statusSelected, setStatusSelected] = useState(indexOfDefaultStatus);
  const [openAlert, setOpenAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState("success");

  
  const statusToAssign = {
    waiting_generate_doctor_contract: [
      "waiting_supplier_company_input_vacancies_data",
      "waiting_generate_doctor_contract",
      "waiting_doctor_sign_contract",
    ],
    waiting_doctor_sign_contract: [
      "waiting_supplier_company_input_vacancies_data",
      "waiting_generate_doctor_contract",
      "waiting_doctor_sign_contract",
      "waiting_payment_doctor",
    ],
    waiting_payment_doctor: ["waiting_payment_doctor", "done"],
    done: ["done"],
  };

  const clickBackStatus = (e) => {
    const newStatus = statusSelected-1;

    const newStatusIsEnabled = statusToAssign[advancePayment.status].includes(listOrderedOfStatus[newStatus])
    
    if(newStatusIsEnabled){
      setStatusSelected(newStatus);
      setStatus(listOrderedOfStatus[newStatus]);
    }
  };

  const clickNextStatus = (e) => {
    const newStatus = statusSelected+1;
    const newStatusIsEnabled = statusToAssign[advancePayment.status].includes(listOrderedOfStatus[newStatus])

    if(newStatusIsEnabled){
      setStatusSelected(newStatus);
      setStatus(listOrderedOfStatus[newStatus]);
    }
  };

  const changeStatus = async () => {
    try{
      console.log(advancePayment.id, status)
      await updateStatus(advancePayment.id, status);
      showAlertSuccess("Status atualizado com sucesso!")
      if (typeof setOpen === "function") {
        setOpen(false);
      }
      if(typeof updateData === "function"){
        await updateData();
      }
    } catch(e) {
      console.error(e);
      showAlertError("Ocorreu um erro ao atualizar status!")
    }
  }

  const onClose = (e) => {
    if (typeof handleClose === "function") {
      handleClose(e);
    }
    if (typeof setOpen === "function") {
      setOpen(false);
    }
  };

  const showAlertSuccess = (message) => {
    setMessageAlert(message);
    setTypeAlert("success");
    setOpenAlert(true);
  };

  const showAlertError = (message) => {
    setMessageAlert(message);
    setTypeAlert("error");
    setOpenAlert(true);
  };

  useEffect(() => {
    if (advancePayment && advancePayment.status) {
      setStatus(advancePayment.status);
      setStatusSelected(indexOfDefaultStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="md">
      <SnackBar
        open={openAlert}
        setOpen={setOpenAlert}
        duration={3000}
        type={typeAlert}
        message={messageAlert}
      />
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <IconButton onClick={clickBackStatus}>
            <WestIcon />
          </IconButton>
        </Box>
        <Box>
          {statusTranslate(
            status
          )}
        </Box>
        <Box>
          <IconButton onClick={clickNextStatus}>
            <EastIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      {/* <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Let Google help apps determine location. This means sending anonymous
          location data to Google, even when no apps are running.
        </DialogContentText>
      </DialogContent> */}
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button color="error" onClick={onClose}>
          Cancelar
        </Button>
        <Button onClick={changeStatus}>
          Atualizar Status
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeStatusModal;
