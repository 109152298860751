export const statusTranslate = (status) => {
  const dictStatus =  {
    'waiting_supplier_company_input_vacancies_data': "Aguardando preenchimento dos dados dos plantões",
    'waiting_generate_doctor_contract': "Contrato disponivel para download",
    'waiting_doctor_sign_contract': "Aguardando assinatura do contrato pelo médico",
    'waiting_payment_doctor': "Aguardando realizar pagamento médico",
    'canceled': "Solicitação cancelada",
    'done': "Plantão Antecipado",
  }

  // waiting_generate_doctor_contract: [waiting_supplier_company_input_vacancies_data, waiting_doctor_sign_contract]
  // waiting_doctor_sign_contract: [waiting_supplier_company_input_vacancies_data, waiting_generate_doctor_contract, waiting_payment_doctor]
  // waiting_payment_doctor: [waiting_supplier_company_callback]
  // waiting_supplier_company_callback: [done]

  
  return dictStatus[status] || status;
}

export const getListStatus = () => {
  return [
    'waiting_supplier_company_input_vacancies_data',
    'waiting_generate_doctor_contract',
    'waiting_doctor_sign_contract',
    'waiting_payment_doctor',
    'done',
    'canceled',
  ]
}

export const getAllBrasilStates = () => {
  return [
    { uf: "AC", name: "Acre - AC" },
    { uf: "AL", name: "Alagoas - AL" },
    { uf: "AP", name: "Amapá - AP" },
    { uf: "AM", name: "Amazonas - AM" },
    { uf: "BA", name: "Bahia - BA" },
    { uf: "CE", name: "Ceará - CE" },
    { uf: "DF", name: "Distrito Federal - DF" },
    { uf: "ES", name: "Espírito Santo - ES" },
    { uf: "GO", name: "Goías - GO" },
    { uf: "MA", name: "Maranhão - MA" },
    { uf: "MT", name: "Mato Grosso - MT" },
    { uf: "MS", name: "Mato Grosso do Sul - MS" },
    { uf: "MG", name: "Minas Gerais - MG" },
    { uf: "PA", name: "Pará - PA" },
    { uf: "PB", name: "Paraíba - PB" },
    { uf: "PR", name: "Paraná - PR" },
    { uf: "PE", name: "Pernambuco - PE" },
    { uf: "PI", name: "Piauí - PI" },
    { uf: "RJ", name: "Rio de Janeiro - RJ" },
    { uf: "RN", name: "Rio Grande do Norte - RN" },
    { uf: "RS", name: "Rio Grande do Sul - RS" },
    { uf: "RO", name: "Rondônia - RO" },
    { uf: "RR", name: "Roraíma - RR" },
    { uf: "SC", name: "Santa Catarina - SC" },
    { uf: "SP", name: "São Paulo - SP" },
    { uf: "SE", name: "Sergipe - SE" },
    { uf: "TO", name: "Tocantins - TO" },
  ];
}