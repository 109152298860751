import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_ANTECIPE_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Authorization",
    "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE",
    "Content-Type": "application/json;charset=UTF-8",
  },
});

//api.interceptors.request.use(async (config) => {});

/*const createAxiosResponseInterceptor = () => {
  const interceptor = api.interceptors.response.use(
    (response) => response,
    (error) => {
        console.error(error);
        return error;
    }
  );
};
createAxiosResponseInterceptor();*/

export default api;
