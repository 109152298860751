import api from "~/services/apis/antecipeApi";

export const getAdvanceRequests = async (limitParams, filtersParams) => {
  return api.get(
    `antecipebackoffice/advancepayment?limit=${limitParams.limit}&offset=${limitParams.offset}`,
    { params: filtersParams }
    // + `?startDate=${startDate}&endDate=${endDate}&crm=${crm}&crmUf=${crmuf}`
  );
};

export const updateDatePayment = (id, date) => {

  if(id && date){
    return api.patch(`antecipebackoffice/advancepayment/${id}`, { date: date })
  }else {
    throw new Error(`Id ou data inválida. ID: ${id} | Date: ${date}`)
  }

}

export const getUrlForContract = async (id, type) => {
  if(id){
    try {
      await api.get(`antecipebackoffice/advancepayment/${id}/${type}/contract/download`);
      return api.defaults.baseURL + `antecipebackoffice/advancepayment/${id}/${type}/contract/download`
    }
    catch(e) {
      console.error(e)
      if(e.response.data.message === "Doctor without Data Banks"){
        const e = new Error("Verifique se você cadastrou os dados bancarios do médico!");
        e.name = 'without-data'
        throw e;
      }
      else if(e.response.data.message === "Doctor without Data Company"){
        const e = new Error("Verifique se você cadastrou os dados da pessoa jurídica do médico!");
        e.name = 'without-data'
        throw e;
      } else{
        const e = new Error("Ocorreu um erro interno do sistema, entre em contato com o suporte!");
        e.name = 'internal-error'
        throw e;
      }
    }
  }else {
    throw new Error(`Id invalido: ${id}`)
  }
}

export const updateStatus = async (id, status) => {

  if(status && id){
    return api.patch(`antecipebackoffice/advancepayment/${id}/status`, { status: status })
  }else {
    throw new Error(`Status ou ID invalido: status: ${status} | id: ${id}`)
  }

}

export const cancel = async (id) => {
  if(id){
    return api.patch(`antecipebackoffice/advancepayment/${id}/cancel`)
  }else {
    throw new Error(`ID invalido: status: ${id}`)
  }
}