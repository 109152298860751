import Box from "@mui/material/Box";
import { DataGrid, ptBR } from "@mui/x-data-grid";
import { getAdvanceRequests } from "~/services/medicalcompany/advanceRequest";
import { useEffect } from "react";
import { useState, useRef } from "react";
import Button from "@mui/material/Button";
import DutysModal from "~/components/modals/DutysModal";
import { date_AAAA_MM_DD_To_DD_MM_AAAA } from "~/utils/date";
import { getDutysByIdDoctor } from "~/services/medicalcompany/dutys";
import { sendToAntecipe } from "~/services/medicalcompany/advanceRequest";
import CustomPagination from "~/components/CustomPagination/index";
import SnackBar from "~/components/modals/SnackBar/index";
import { statusTranslate } from "~/utils/advanceRequest";
import FilterBarAdvanceRequest from "../../FilterBarAdvanceRequest";

// const rows = [
//   { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
//   { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
//   { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
//   { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
//   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
//   { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
//   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
//   { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
//   { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
// ];

const LIMIT = 10;
const LIMIT_DUTYS = 10;

export default function TableMedicalCompany() {
  const [rows, setRows] = useState([]);
  const [openDutysModal, setOpenDutysModal] = useState(false);
  const [dutysByDoctor, setDutysByDoctor] = useState([]);
  const [doctorIdSelected, setDoctorIdSelected] = useState(null);
  const [totalDutysRegisters, setTotalDutysRegisters] = useState(0);
  const [totalRegisters, setTotalRegisters] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState("success");
  const [filterBarHeight, setFilterBarHeight] = useState(0)
  const [filters, setFilters] = useState({})
  const [sortModel, setSortModel] = useState([
    { field: 'request_date', sort: 'desc' },
  ]);

  const refFilterBar = useRef(null);

  const handleSortModelChange = (newModel) => {
    if(newModel.length === 0){
        const orderByRequestDate = sortModel.filter(item => item.field === 'request_date')[0]
        console.log(orderByRequestDate)
        if(orderByRequestDate.sort === 'desc'){
          const sortModelTemp = [
            { field: 'request_date', sort: 'asc' },
          ]
          console.log(sortModelTemp)
          setSortModel(sortModelTemp);
          applyOrder(sortModelTemp)
        }else{
          const sortModelTemp = [
            { field: 'request_date', sort: 'desc' },
          ]
          console.log(sortModelTemp)
          setSortModel(sortModelTemp);
          applyOrder(sortModelTemp)
        }
      }else{
        setSortModel(newModel);
        applyOrder(newModel)
      }
  };

  const ButtonShowDutys = ({ params }) => {
    const onClickShowDutysModal = async () => {
      setDoctorIdSelected(params.row.id);
      await updateListDutys(params.row.id, { limit: LIMIT_DUTYS, offset: 0 });
    };
    return <Button onClick={onClickShowDutysModal}>Ver Plantões</Button>;
  };

  const ButtonSendToAntecipe = ({ params }) => {
    const onClick = async () => {
      try {
        await sendToAntecipe(params.row.id);
        await getData();
        showAlertSuccess("Solicitação enviada para equipe da Antecipe!");
      } catch (e) {
        showAlertError("Erro ao enviar solicitação para a Antecipe!");
        console.error(e);
      }
    };

    const isDisabled =
      params.row.checked_vacancies !== params.row.vacancies_qty;

    return (
      <Button disabled={isDisabled} onClick={onClick}>
        Enviar para Antecipe
      </Button>
    );
  };

  const updateListDutys = async (idDoctor, params) => {
    setOpenDutysModal(true);
    const dutysByIdDoctor = await getDutysByIdDoctor(
      idDoctor ? idDoctor : doctorIdSelected,
      params
    );
    setTotalDutysRegisters(dutysByIdDoctor.total_items);
    setDutysByDoctor(dutysByIdDoctor.data);
  };

  const createFilterObject = (filters) => {
    const filterObject = {};

    // Verificar se doctorName possui valor
    if (filters.doctorName) {
      filterObject.fullName = filters.doctorName;
    }

    // Verificar se crm possui valor
    if (filters.crm) {
      filterObject.crm = filters.crm;
    }

    // Verificar se crmUF possui valor
    if (filters.crmUf) {
      filterObject.crmUf = filters.crmUf;
    }

    // Verificar se status possui valor
    if (filters.selectedStatus && filters.selectedStatus.length > 0) {
      filterObject.status = filters.selectedStatus;
    }

    // Verificar se startRequestDate possui valor
    if (filters.startRequestDate) {
      filterObject.startRequestDate = filters.startRequestDate;
    }

    // Verificar se endRequestDate possui valor
    if (filters.endRequestDate) {
      filterObject.endRequestDate = filters.endRequestDate;
    }

    return filterObject;
  };

  const applyOrder = async (sortModelPaylod) => {
    const orderByRequestDate = sortModelPaylod.filter(item => item.field === 'request_date')[0]
    if(orderByRequestDate){
      await getData(1, { ...filters, orderByRequestDate: orderByRequestDate.sort});
    }
  }

  const applyFilters = async (params) => {
    setPage(1);
    const filters = createFilterObject(params);
    setFilters(filters);
    setPage(1);
    const formatedFilters = createFilterObject(params);
    const orderByRequestDate = sortModel.filter(item => item.field === 'request_date')[0]
    await getData(1, { ...formatedFilters, orderByRequestDate: orderByRequestDate.sort});
  };

  const getData = async (pageSelected, filtersParams) => {
    setIsLoading(true);
    pageSelected = pageSelected ? pageSelected : page;

    const data = await getAdvanceRequests(
      {
        limit: LIMIT,
        offset: (pageSelected - 1) * LIMIT,
      },
      filtersParams
    );

    const totalPages = Math.ceil(data.data.total_items / LIMIT);

    if (pageSelected > totalPages && totalPages !== 0) {
      setPage(totalPages);
      const _data = await getAdvanceRequests(
        {
          limit: LIMIT,
          offset: (totalPages - 1) * LIMIT,
        },
        filtersParams
      );
      setTotalRegisters(_data.data.total_items);
      setRows(_data.data.data);
      setIsLoading(false);
    } else {
      setTotalRegisters(data.data.total_items);
      setRows(data.data.data);
      setIsLoading(false);
    }
  };

  const handleChangePage = async (event, value) => {
    await getData(value);
  };

  const showAlertSuccess = (message) => {
    setMessageAlert(message);
    setTypeAlert("success");
    setOpenAlert(true);
  };

  const showAlertError = (message) => {
    setMessageAlert(message);
    setTypeAlert("error");
    setOpenAlert(true);
  };

  useEffect(() => {
    getData();
    setFilterBarHeight(refFilterBar.current.clientHeight)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "doctor_name",
      headerName: "Nome",
      width: 180,
      editable: false,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "doctor_crm",
      headerName: "CRM",
      width: 70,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "doctor_crm_uf",
      headerName: "CRMUF",
      width: 90,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "company_name",
      headerName: "Empresa",
      width: 180,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "request_date",
      headerName: "Data da solicitação",
      width: 200,
      valueFormatter: ({ value }) =>
        date_AAAA_MM_DD_To_DD_MM_AAAA(value.slice(0, 10)),
      editable: false,
      sortable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "verifiedsAndTotal",
      headerName: "Verificados | Total",
      width: 140,
      valueGetter: ({ row }) =>
        row.checked_vacancies + " | " + row.vacancies_qty,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: 340,
      editable: false,
      sortable: false,
      align: "center",
      valueFormatter: ({ value }) => statusTranslate(value),
      headerAlign: "center",
    },
    {
      field: "show-dutys",
      headerName: "Plantões",
      width: 140,
      renderCell: (params) => <ButtonShowDutys params={params} />,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "send-to-antecipe",
      headerName: "Enviar para Antecipe",
      width: 200,
      renderCell: (params) => <ButtonSendToAntecipe params={params} />,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
  ];

  return (
    <>
      <DutysModal
        open={openDutysModal}
        setOpen={setOpenDutysModal}
        dutys={dutysByDoctor}
        setDutys={setDutysByDoctor}
        updateListDutys={updateListDutys}
        totalRegisters={totalDutysRegisters}
        updateRequestList={getData}
      />
      <SnackBar
        open={openAlert}
        setOpen={setOpenAlert}
        duration={3000}
        type={typeAlert}
        message={messageAlert}
      />
      <Box sx={{ height: "calc(100vh - 64px)", width: "100%" }}>
        <Box
          sx={{
            paddingTop: "12px",
            paddingBottom: "12px",
            paddingLeft: "12px",
          }}
          ref={refFilterBar}
        >
          <FilterBarAdvanceRequest onFilterChange={applyFilters} />
        </Box>
        <Box sx={{ height: `calc(100vh - ${filterBarHeight}px - 66px)` }}>
        {/* {filterBarHeight} */}
          <DataGrid
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={rows}
            columns={columns}
            disableColumnFilter
            loading={isLoading}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            components={{
              Pagination: () => (
                <CustomPagination
                  count={Math.ceil(totalRegisters / LIMIT)}
                  page={page}
                  setPage={setPage}
                  onChange={handleChangePage}
                />
              ),
            }}
            disableSelectionOnClick
          />
        </Box>
      </Box>
    </>
  );
}
