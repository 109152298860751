import { Pagination } from "@mui/material";

const CustomPagination = ({ count, page, setPage, onChange }) => {
  const handleChange = (event, value) => {
    if(typeof setPage === "function"){
      setPage(value);
    }
    if(typeof onChange === "function"){
      onChange(event, value);
    }
  };

  return (
    <Pagination page={page} count={count} onChange={handleChange} color="primary" />
  );
};

export default CustomPagination;
