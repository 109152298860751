import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { DataGrid, ptBR } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import { date_AAAA_MM_DD_To_DD_MM_AAAA } from "~/utils/date";
import { useState } from "react";
import { formatDateInHoursAndMinutes } from "~/utils/date";
import SnackBar from "~/components/modals/SnackBar/index";
import CustomPagination from "~/components/CustomPagination/index";
import SyncIcon from "@mui/icons-material/Sync";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { syncDutyById } from "~/services/medicalcompany/dutys";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { numberToBRL } from '~/utils/number';

const LIMIT_DUTYS = 10;

const DutysModal = ({
  open,
  setOpen,
  handleClose,
  dutys,
  setDutys,
  updateListDutys,
  totalRegisters,
  updateRequestList
}) => {
  const [openAlert, setOpenAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState("success");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  const CheckBoxDutys = ({ params }) => {
    return params.value ? <CheckIcon color="primary"/> : <CloseIcon color="error"/>
  };

  const SyncButton = ({ params }) => {
    const onClick = async (e) => {
      try {
        await syncDutyById(params.row.id)
        setMessageAlert("Plantão sincronizado com sucesso!");
        setTypeAlert("success");
        setOpenAlert(true);
        try {
          await updateListDutysWithParmas()
        } catch(e) {
          console.error(e);
        } 
      } catch (e) {
        setTypeAlert("error");
        setMessageAlert("Ocorreu algum erro ao sincronizar o plantão!");
        setOpenAlert(true);
      }
    }

    return (
      <IconButton onClick={onClick}>
        <SyncIcon />
      </IconButton>
    );
  };

  const updateListDutysWithParmas = async (pageParam) => {

    pageParam = pageParam ? pageParam : page

    if(typeof updateListDutys === "function"){
      try {
        setIsLoading(true)
        await updateListDutys(false, { limit: LIMIT_DUTYS, offset: (pageParam-1)*LIMIT_DUTYS })
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
      }
    }
  }

  const handleChangePage = async (event, value) => {
    setPage(value);
    await updateListDutysWithParmas(value)
  }

  const onClose = async (e) => {
    if (typeof handleClose === "function") {
      handleClose(e);
    }
    if (typeof setOpen === "function") {
      setOpen(false);
      setPage(1);
      if(typeof updateRequestList === "function"){
        await updateRequestList();
      }
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "hospital_name",
      headerName: "Hospital",
      width: 200,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    // {
    //   field: "hospital",
    //   headerName: "Hospital",
    //   width: 200,
    //   editable: false,
    //   align: "center",
    //   headerAlign: "center",
    // },
    
    {
      field: "date",
      headerName: "Data",
      width: 110,
      valueFormatter: ({ value }) =>
        date_AAAA_MM_DD_To_DD_MM_AAAA(value.slice(0, 10)),
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "normal_payment_date",
      headerName: "Data de pagamento normal",
      width: 200,
      valueFormatter: ({ value }) =>
        date_AAAA_MM_DD_To_DD_MM_AAAA(value.slice(0, 10)),
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "gross_value",
      headerName: "Valor bruto",
      width: 110,
      valueFormatter: ({ value }) => {
        const valueFormated = numberToBRL(value) //Number(value).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
        return valueFormated
      },
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "real_net_value",
      headerName: "Valor Proporcional",
      width: 140,
      valueFormatter: ({ value }) => {
        const valueFormated = numberToBRL(value) //Number(value).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
        return valueFormated
      },
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "percentage_time",
      headerName: "Porcentagem Trabalhada",
      width: 160,
      valueFormatter: ({ value }) => {
        return value * 100 + "%"
      },
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "start_hour",
      headerName: "Hora de inicio",
      width: 110,
      valueFormatter: ({ value }) => {
        const hour = value ? formatDateInHoursAndMinutes(new Date(value)) : "Não informado"
        return hour;
      },
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "end_hour",
      headerName: "Hora da saida",
      width: 110,
      valueFormatter: ({ value }) => {
        const hour = value ? formatDateInHoursAndMinutes(new Date(value)) : "Não informado"
        return hour;
      },
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "real_start_hour",
      headerName: "Hora de inicio realizada",
      width: 170,
      valueFormatter: ({ value }) => {
        const hour = value ? formatDateInHoursAndMinutes(new Date(value)) : "Não informado"
        return hour;
      },
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "real_end_hour",
      headerName: "Hora da saida realizada",
      width: 170,
      valueFormatter: ({ value }) => {
        const hour = value ? formatDateInHoursAndMinutes(new Date(value)) : "Não informado"
        return hour;
      },
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "with_discount",
      headerName: "Com desconto?",
      width: 130,
      renderCell: (params) => <CheckBoxDutys params={params} />,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "verified_documents",
      headerName: "Documentos Verificados?",
      width: 200,
      renderCell: (params) => <CheckBoxDutys params={params} />,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "can_advance",
      headerName: "Pode Adiantar?",
      width: 130,
      renderCell: (params) => <CheckBoxDutys params={params} />,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "is_checked",
      headerName: "Já Revisado?",
      width: 130  ,
      renderCell: (params) => <CheckBoxDutys params={params} />,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    // {
    //   field: "obs",
    //   headerName: "Observação",
    //   width: 300,
    //   editable: true,
    // },
    // {
    //   field: "actions",
    //   type: "actions",
    //   width: 120,
    //   renderCell: (params) => />,
    //   editable: false,
    // },
  ];

  return (
    <Dialog open={open} onClose={onClose} fullScreen={true} maxWidth="xl">
      <SnackBar
        open={openAlert}
        setOpen={setOpenAlert}
        duration={3000}
        type={typeAlert}
        message={messageAlert}
      />
      <DialogTitle 
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'center',
        }}
      >
        <IconButton onClick={onClose} sx={{ mr: '6px' }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography sx={{ fontWeight: 'bold', fontSize: "1.3rem" }}>Validar plantões do médico</Typography>
      </DialogTitle>
      <DialogContent>
        <DataGrid
         
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rows={dutys}
          autoHeight={true}
          columns={columns}
          pageSize={10}
          loading={isLoading}
          // autoPageSize
          disableColumnFilter
          components={{
            Pagination: () => <CustomPagination count={Math.ceil(totalRegisters/LIMIT_DUTYS)} page={page} setPage={setPage} onChange={handleChangePage} />
          }}
          disableSelectionOnClick
          // rowsPerPageOptions={[10]}
        />
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ ml: "16px" }}>
          <Button
            sx={{ mr: "6px" }}
            variant="outlined"
            color="error"
            onClick={onClose}
          >
            Fechar
          </Button>
        </Box>
      </DialogActions>
      {/* onPageChange={onPageChange}
        onSortModelChange={onSortModelChange} */}
    </Dialog>
  );
};

export default DutysModal;
