import api from "~/services/apis/antecipeApi";

export const getAdvanceRequests = async (paramsFixed, filtersParams) => {
  return api.get(
    `medicalcompany/doctorrequets?limit=${paramsFixed.limit}&offset=${paramsFixed.offset}`,
    { params: filtersParams }
  );
};

export const sendToAntecipe = async (requestId) => {
  return await api.put(`medicalcompany/advancepayment/${requestId}/complete`);
};
