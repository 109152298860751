import SaveIcon from "@mui/icons-material/Save";
import { CircularProgress } from "@mui/material";

const SaveButton = ({
  width,
  height,
  marginLeft,
  background,
  iconSize,
  iconColor,
  loading,
  onClick,
}) => {
  const handleClick = (event) => {
    if (typeof onClick === "function") {
      onClick(event);
    }
  };

  const widthToNumber = Number(width.replace("px", ""));
  const progressSize = widthToNumber * 0.7;

  return (
    <button
      style={{
        width: width,
        height: height,
        marginLeft: marginLeft,
        background: background,
        border: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "3px",
      }}
      onClick={handleClick}
    >
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
          }}
        >
          <CircularProgress size={progressSize} color="inherit" />
        </div>
      ) : (
        <SaveIcon sx={{ fontSize: iconSize, color: iconColor }} />
      )}
    </button>
  );
};

SaveButton.defaultProps = {
  width: "21.5px",
  height: "21.5px",
  marginLeft: "8px",
  background: "#1876d2",
  iconSize: "18px",
  iconColor: "white",
  onClick: () => {},
};

export default SaveButton;
