import { useState } from "react";
import { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import SnackBar from "~/components/modals/SnackBar/index";
import {
  showDoctorCompany,
  updateDoctorCompany,
} from "~/services/antecipebackoffice/doctors";

const EditDoctorPJModal = ({
  open,
  setOpen,
  handleClose,
  idDoctor,
  doctorName,
}) => {
  const [doctorCompany, setDoctorCompany] = useState({
    company_name: "",
    cnpj: "",
    company_address: "",
    company_cep: "",
    rg: "",
    rg_issuer: "",
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState("success");

  const onClose = (e) => {
    if (typeof handleClose === "function") {
      handleClose(e);
    }
    setOpen(false);
  };

  const editDoctorCompany = async () => {
    try {
      await updateDoctorCompany(idDoctor, doctorCompany);
      showAlertSuccess("Dados PJ Atualizado com Sucesso!");
    } catch (e) {
      showAlertError(
        "Erro ao atualizar dado PJ, por favor preencha todos os campos obrigatórios!"
      );
      console.error(e);
    }
  };

  const setAttrDoctorCompany = (attr, value) => {
    setDoctorCompany({
      ...doctorCompany,
      [attr]: value,
    });
  };

  const getData = async () => {
    const { data } = await showDoctorCompany(idDoctor);

    setDoctorCompany(data);
  };

  const showAlertSuccess = (message) => {
    setMessageAlert(message);
    setTypeAlert("success");
    setOpenAlert(true);
  };

  const showAlertError = (message) => {
    setMessageAlert(message);
    setTypeAlert("error");
    setOpenAlert(true);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
      <SnackBar
        open={openAlert}
        setOpen={setOpenAlert}
        duration={3000}
        type={typeAlert}
        message={messageAlert}
      />
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Dados Pessoa Jurídica
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          // justifyContent: "space-between",
          // alignItems: "center",
        }}
      >
        <Typography sx={{ mb: "12px", mt: "-5px" }}>
          Médico: {doctorName}
        </Typography>
        <TextField
          sx={{ mb: "12px", mt: "6px" }}
          required
          label="Razão Social"
          value={doctorCompany.company_name}
          onChange={(e) => {
            setAttrDoctorCompany("company_name", e.target.value);
          }}
        />
        <TextField
          sx={{ mb: "12px", mt: "6px" }}
          required
          label="CNPJ"
          value={doctorCompany.cnpj}
          onChange={(e) => {
            setAttrDoctorCompany("cnpj", e.target.value);
          }}
        />
        <TextField
          sx={{ mb: "12px", mt: "6px" }}
          required
          label="Endereço"
          value={doctorCompany.company_address}
          onChange={(e) => {
            setAttrDoctorCompany("company_address", e.target.value);
          }}
        />
        <TextField
          sx={{ mb: "12px", mt: "6px" }}
          required
          label="CEP"
          value={doctorCompany.company_cep}
          onChange={(e) => {
            setAttrDoctorCompany("company_cep", e.target.value);
          }}
        />
        <TextField
          sx={{ mb: "12px", mt: "6px" }}
          required
          label="RG"
          value={doctorCompany.rg}
          helperText="Insira os pontos (.) e traços (-) no RG se for necessário."
          onChange={(e) => {
            setAttrDoctorCompany("rg", e.target.value);
          }}
        />
        <TextField
          sx={{ mb: "12px", mt: "6px" }}
          required
          label="Emissor do RG"
          value={doctorCompany.rg_issuer}
          onChange={(e) => {
            setAttrDoctorCompany("rg_issuer", e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button color="error" onClick={onClose}>
          Cancelar
        </Button>
        <Button onClick={editDoctorCompany}>Atualizar dados PJ</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDoctorPJModal;
