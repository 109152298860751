import Box from "@mui/material/Box";
import Stepper from "./components/Stepper";


const AntecipeForm = () => {
  return (
    <Box
      sx={{
        width: '100%',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#e7ebf0"
      }}
    >
      <Stepper />
    </Box>
  );
}

export default AntecipeForm;