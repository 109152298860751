import { Alert, Snackbar } from "@mui/material";

const SnackBar = ({ open, handleClose, setOpen, duration, type, message }) => {
  const onClose = (e, reason) => {
    if (typeof setOpen === "function") {
      if (reason === 'clickaway') {
        return;
      }

      setOpen(false);
    }
    if (typeof handleClose === "function") {
      handleClose(e);
    }
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={duration ? duration : 5000}
      onClose={onClose}
    >
     <Alert onClose={onClose} severity={type} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar> 
  );
};

export default SnackBar;
