import api from "~/services/apis/antecipeApi";
import { formatDateInHoursAndMinutes } from '~/utils/date';

export const getDutysByIdDoctor = async (id, params) => {
  try {
    console.log(params);
    const data = await api.get(`antecipebackoffice/advancepayment/${id}/vacancies?limit=${params.limit}&offset=${params.offset}`);
    const vacancies = data.data.data;
    const listVacancies = vacancies.map((vacancie) => {
      return {
        ...vacancie,
        // real_end_hour: vacancie.real_end_hour ? formatDateInHoursAndMinutes(new Date(vacancie.real_end_hour)) : null,
        // real_start_hour: vacancie.real_start_hour ? formatDateInHoursAndMinutes(new Date(vacancie.real_start_hour)) : null
      }
    })
    return {
      data: listVacancies,
      total_items: data.data.total_items
    };
  } catch(e) {
    console.log('Error', e)
  }
};