import Box from "@mui/material/Box";
import { Typography, Grid } from "@mui/material";
import CapaHeader from "~/assets/capa-header.png";
import BgCapaTop from "~/assets/background-capa-top.png";
import Logo from "~/assets/logo.png";
import AntecipeMoney from "~/assets/antecipe-money.png";

const HeaderDesktopStep4 = () => {
  return (
    <Box sx={{ marginBottom: '3%' }}>
      <Box sx={{
        height: '60%',
        width: '97%'
      }}>
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6} sx={{
          }}>
            <Box></Box>
          </Grid>
          <Grid item xs={6} sx={{
            marginLeft: '-4%',
            padding: '0',
            width: '100%',
          }}>
            <Box sx={{
              width: '66%',
            }}>
              <img
                src={AntecipeMoney}
                alt="capa antecipe"
                style={{ width: '60%', marginTop: '-15%' }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className='dot_step4' sx={{
        zIndex: '0',
        position: 'inherit',
        marginTop: '-7%'
      }}>
      </Box>
      <Box sx={{
        textAlign: 'center',
        width: '300px',
        height: '24px',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '20px',
        color: 'black',
        textAlign: 'center',
        backgroundColor: '#70e3bb',
        paddingTop: '12px',
        paddingBottom: '18px',
        paddingRight: '20px',
        paddingLeft: '20px',
        marginTop: '-5.5vw',
        marginLeft: 'auto',
        marginRight: 'auto',
        whiteSpace: 'nowrap'
      }}>
        <Typography variant="body">
          <Box
            component="span"
            sx={{
              fontWeight: 'Bold',
            }}>Plantões de antecipação</Box>
        </Typography>
      </Box>
    </Box>
  );
}

export default HeaderDesktopStep4;