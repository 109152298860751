export const date_DD_MM_AAAA_To_MM_DD_AAAA = (date) => {
  date = date.replaceAll("-", "/");
  let dateSplited = date.split("/");
  return dateSplited[1] + "/" + dateSplited[0] + "/" + dateSplited[2];
};

export const date_DD_MM_AAAA_To_AAAA_MM_DD = (date) => {
  let dateSplited = date.split("-");
  return dateSplited[2] + "-" + dateSplited[1] + "-" + dateSplited[0];
};

export const date_AAAA_MM_DD_To_DD_MM_AAAA = (date) => {
  let dateSplited = date.substr(0, 10).split("-");
  return dateSplited[2] + "-" + dateSplited[1] + "-" + dateSplited[0];
};

export const maskHourAndMinutes = (hourAndMinutes) => {
  hourAndMinutes = hourAndMinutes != undefined ? hourAndMinutes.replaceAll(":", "") : "";
  const hour = hourAndMinutes.substr(0,2)
  const minute = hourAndMinutes.substr(2,4)

  if(hourAndMinutes.length > 2) {
    return hour + ":" + minute
  }

  return hour;
}

export const formatDateInHoursAndMinutes = (dateTime) => {
  let hours = `0${dateTime.getHours()}`.slice(-2);
  let minutes = `0${dateTime.getMinutes()}`.slice(-2);
  // hours = hours <= 9 ? "0" + hours : hours;
  hours = hours.substring(0,3);
  return  hours + ':' + minutes;
}