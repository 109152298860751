import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const Step6 = ({ setDisabledNext, setActiveStep }) => {
  const isMobile = useMediaQuery("(max-width:1024x)");

  setDisabledNext(true);
  return (
    <Box
      sx={{
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "82px",
        marginLeft: "26px",
        marginRight: "26px",
      }}
    >
      <CheckCircleIcon sx={{ fontSize: "80px", color: "#17384c" }} />

      <Typography align="center" sx={{ fontSize: "28px", marginTop: "22px" }}>
        <strong>Solicitação enviada com sucesso!</strong>
      </Typography>

      <Typography align="center" sx={{ fontSize: "16px", marginTop: "22px" }}>
        Sua solicitação foi enviada com sucesso e em breve nossa equipe entrará
        em contato.
      </Typography>

      <Box  pt={2}>
        <Typography
          sx={{
            textAlign: "center",
            marginTop: "18px",
            marginBottom: "12px",
            fontWeight: "bold",
            fontSize: "18px",
            color: "#17384c",
          }}
        >
          Em caso de dúvidas, fale com nossa equipe de atendimento:
          via WhatsApp ou Telefone: 19 9995-7432
          <WhatsAppIcon sx={{ marginBottom: "-5px" }} />
        </Typography>
        <Box sx={{marginTop: "22px", display: 'flex', justifyContent: 'center'}}>
          <Button
            size="large"
            onClick={() => {
              setActiveStep(0);
            }}
            sx={{
              fontFamiliy: "Poppins, sans-serif",
              fontWeight: "bold",
              backgroundColor: "#17384c",
              color: "white",
              "&:hover": {
                backgroundColor: "#05bbe5",
              },
            }}
          >
            Voltar para o inicio
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Step6;
