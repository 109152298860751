import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
} from "@mui/material";
import {
  getListStatus,
  statusTranslate,
  getAllBrasilStates,
} from "../../utils/advanceRequest";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const states = getAllBrasilStates();

const defaultValues = {
  doctorName: "",
  crm: "",
  crmUf: "",
  selectedStatus: [],
  startRequestDate: "",
  endRequestDate: "",
};

const FilterBarAdvanceRequest = ({ onFilterChange }) => {
  const [filters, setFilters] = useState({ ...defaultValues });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const clearFilters = () => {
    setFilters({ ...defaultValues });
    if (typeof onFilterChange == "function") {
      onFilterChange({ ...defaultValues });
    }
  };

  const handleStatusChange = (event) => {
    const { value } = event.target;
    const _filters = {
      ...filters,
      selectedStatus: typeof value === "string" ? value.split(",") : value,
    };
    setFilters(_filters);
    if (typeof onFilterChange == "function") {
      onFilterChange(_filters);
    }
  };

  const handleApplyFilters = () => {
    // Aqui você pode chamar uma função de callback passando os filtros atualizados
    if (typeof onFilterChange == "function") {
      onFilterChange(filters);
    }
  };

  useEffect(() => {
    const listener = event => {
      console.log(event, event.code, filters)
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        if (typeof onFilterChange == "function") {
          onFilterChange(filters);
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [filters]);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      <TextField
        sx={{ m: 1, width: 200 }}
        label="Nome do Médico"
        name="doctorName"
        value={filters.doctorName}
        onChange={handleInputChange}
      />
      <TextField
        sx={{ m: 1, width: 100 }}
        label="CRM"
        name="crm"
        value={filters.crm}
        onChange={handleInputChange}
      />
      <FormControl sx={{ m: 1, width: 110 }}>
        <InputLabel id="crmuf-select-label">CRMUF</InputLabel>
        <Select
          id="crmuf"
          labelId="crmuf-select-label"
          label="CRMUF"
          name="crmUf"
          value={filters.crmUf}
          renderValue={(s) => s}
          onChange={handleInputChange}
        >
          {states.map((state) => {
            return (
              <MenuItem key={state.uf} value={state.uf}>
                {state.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="status-multiple-checkbox-label">Status</InputLabel>
        <Select
          label="Status"
          labelId="status-multiple-checkbox-label"
          id="status-multiple-checkbox"
          multiple
          value={filters.selectedStatus}
          onChange={handleStatusChange}
          renderValue={(selecteds) =>
            selecteds.map((s) => statusTranslate(s)).join(", ")
          }
          MenuProps={MenuProps}
        >
          {getListStatus().map((slug) => (
            <MenuItem key={slug} value={slug}>
              <Checkbox checked={filters.selectedStatus.indexOf(slug) > -1} />
              <ListItemText primary={statusTranslate(slug)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        sx={{ m: 1, width: 165 }}
        label="Data Inicial"
        type="date"
        name="startRequestDate"
        value={filters.startRequestDate}
        onChange={handleInputChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        sx={{ m: 1, width: 165 }}
        label="Data Final"
        type="date"
        name="endRequestDate"
        value={filters.endRequestDate}
        onChange={handleInputChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Button
        variant="contained"
        sx={{ m: 1, width: 56, height: 56, backgroundColor: "gray" }}
        onClick={clearFilters}
      >
        <FilterAltOffIcon />
      </Button>
      <Button
        sx={{ m: 1, width: 165, height: 56 }}
        variant="contained"
        onClick={handleApplyFilters}
      >
        Aplicar Filtros
      </Button>
    </Box>
  );
};

export default FilterBarAdvanceRequest;
